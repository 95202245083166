import { CampaignData } from './types/campaigns'

export class Campaign {
  data: CampaignData
  status: 'upcoming'|'running'|'ended'
  statusColor: string
  statusLabel: string

  startsAt: Date | null
  endsAt: Date | null

  constructor (data: CampaignData) {
    this.data = data

    this.startsAt = this.data.starts_at ? new Date(this.data.starts_at) : null
    this.endsAt = this.data.ends_at ? new Date(this.data.ends_at) : null
    this.status = this.getStatus()
    this.statusColor = this.getStatusColor()
    this.statusLabel = this.getStatusLabel()
  }

  hasStarted (): boolean {
    return this.startsAt ? this.startsAt < new Date() : true
  }

  hasEnded (): boolean {
    return this.endsAt ? this.endsAt < new Date() : false
  }

  getStatus (): 'upcoming'|'running'|'ended' {
    if (this.hasEnded()) return 'ended'
    if (this.hasStarted()) return 'running'
    return 'upcoming'
  }

  getStatusColor (): string {
    switch (this.getStatus()) {
      case 'upcoming': return 'grey'
      case 'running': return 'blue'
      case 'ended': return 'green'
    }
  }

  getStatusLabel (): string {
    switch (this.getStatus()) {
      case 'upcoming': return `Upcoming (${this.startsAt?.toLocaleDateString()})`
      case 'running': return 'Running' + (this.startsAt ? ` since ${this.startsAt.toLocaleDateString()}` : '') + (this.endsAt ? ` until ${this.endsAt.toLocaleDateString()}` : '')
      case 'ended': return `Ended (${this.endsAt?.toLocaleDateString()})`
    }
  }
}
