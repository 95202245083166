
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { CampaignSheet } from '@/types/i18n'
import { SnackbarMessage } from '@/types/snackbar'

@Component
export default class Sheet extends Vue {
  @Getter('campaigns/currentTranslationSheetUrl') currentSheet!: string | null
  @Action('campaigns/EDIT_CURRENT_TRANSLATION_SHEET') editSheet!: (data: CampaignSheet) => Promise<void>
  @Action('campaigns/REMOVE_CURRENT_TRANSLATION_SHEET') removeSheetAction!: () => Promise<void>
  @Action('snackbar/SHOW_MESSAGE') showMessage!: (msg: SnackbarMessage) => Promise<void>
  @Getter('campaigns/editLoading') editLoading!: boolean

  value: string | null = null

  get sheetUrl (): string|null {
    return this.value || this.currentSheet
  }

  set sheetUrl (value: string|null) {
    this.value = value
  }

  get submitDisabled (): boolean {
    return !this.sheetUrl || this.editLoading
  }

  async removeSheet (): Promise<void> {
    this.value = null
    await this.removeSheetAction()
  }

  async submit () {
    if (!this.sheetUrl) {
      return
    }

    await this.editSheet({ sheet_id: this.sheetUrl })

    await this.showMessage({ content: 'Translations updated!', color: 'success' })
  }
}
