
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { CampaignNewsletter } from '@/types/campaigns'

@Component
export default class Newsletter extends Vue {
  @Getter('campaigns/currentLocaleNames') currentLocaleNames!: [string, string][]
  @Prop({ required: true, type: Object }) value!: CampaignNewsletter

  get newsletterLocales (): [string, string][] {
    return this.currentLocaleNames
  }

  get enabled () {
    return this.value.enabled
  }

  set enabled (value: boolean) {
    this.$emit('input', { ...this.value, enabled: value })
  }

  getFieldValue (locale: string, field: string): string {
    return (this.value.locales[locale] || {})[field] || ''
  }

  updateField (locale: string, field: string, value: string) {
    const localeObj = this.value.locales[locale] || {}
    const newLocale = { ...localeObj, [field]: value }
    const newLocales = { ...this.value.locales, [locale]: newLocale }
    const newValue = { ...this.value, locales: newLocales }
    this.$emit('input', newValue)
  }
}
