import { render, staticRenderFns } from "./LocaleListItem.vue?vue&type=template&id=1c54928a&scoped=true&"
import script from "./LocaleListItem.vue?vue&type=script&lang=ts&"
export * from "./LocaleListItem.vue?vue&type=script&lang=ts&"
import style0 from "./LocaleListItem.vue?vue&type=style&index=0&id=1c54928a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c54928a",
  null
  
)

export default component.exports