
import { Component, Vue, Prop } from 'vue-property-decorator'
import LocaleSearch from './LocaleSearch.vue'
import { SearchLocale } from '@/types/i18n'

@Component({ components: { LocaleSearch } })
export default class NewLocale extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  dialog=false
  value: SearchLocale | null= null

  submit () {
    if (!this.value) {
      return
    }
    this.$emit('submit', this.value)
    this.dialog = false
    this.value = null
  }
}
