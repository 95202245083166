
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TableSummaryItem } from '../types'

@Component
export default class TableSummary extends Vue {
  @Prop() items!: TableSummaryItem[]

  formattedValue (value: string|number): string {
    if (typeof value === 'number') {
      return value.toLocaleString()
    }
    return value
  }
}
