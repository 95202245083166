
import AppHelper from '@/AppHelper'
import { Component, Vue } from 'vue-property-decorator'
import SideNavLink from './SideNavLink.vue'
import { SnackbarMessage } from '@/types/snackbar'
import { Action } from 'vuex-class'
import { copyToClipboard } from '@/util'
import { Campaign } from '@/Campaign'

@Component({ components: { SideNavLink } })
export default class CampaignSidebar extends Vue {
  @Action('snackbar/SHOW_MESSAGE') showMessage: (message: SnackbarMessage) => void

  get campaign (): Campaign {
    return this.$store.getters['campaigns/current']
  }

  get parentCampaign (): Campaign|null {
    return this.$store.getters['campaigns/currentParent']
  }

  get parentApp (): AppHelper|null {
    return this.$store.getters['apps/parentHelper']
  }

  get app (): AppHelper {
    return this.$store.getters['apps/currentHelper']
  }

  get id (): string {
    return this.campaign.data.id
  }

  get canReadObjectAccess (): boolean {
    return this.$store.getters['permissions/canReadObjectAccess']
  }

  copyId (): void {
    copyToClipboard(this.id)
    this.showMessage({
      content: `Copied "${this.id}" to clipboard`,
      timeout: 2000

    })
  }
}
