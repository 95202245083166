
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { LocaleMap, SearchLocale } from '@/types/i18n'
import LocaleListItem from './LocaleListItem.vue'

@Component({ components: { LocaleListItem } })
export default class LocaleSearch extends Vue {
  @Getter('i18n/allLocales') data!: LocaleMap
  @Prop({ type: Object }) value!: SearchLocale | null

  get items () {
    return Object.values(this.data)
  }

  filterLocales (item: SearchLocale, query: string): boolean {
    const ql = query.toLowerCase()
    return item.name.toLowerCase().includes(ql) || item.native_name.toLowerCase().includes(ql)
  }

  get selectedItem (): string| null {
    return this.value?.code || null
  }

  set selectedItem (item: string | null) {
    this.$emit('input', this.data[item || ''] || null)
  }
}
