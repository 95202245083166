
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { User, UserNew } from '../types/users'

@Component
export default class UserNewView extends Vue {
  @Action('users/CREATE_USER') createUser!: (data: UserNew) => Promise<User>

  name = ''
  email = ''
  password = ''

  valid = true
  rules = {
    required: [(value: string) => !!value || 'Required']
  }

  submitLoading = false

  async submit () {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }
    this.submitLoading = true
    try {
      await this.createUser({
        display_name: this.name,
        password: this.password,
        email: this.email
      })
      this.$router.push({ name: 'users' })
    } finally {
      this.submitLoading = false
    }
  }
}
