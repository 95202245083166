
import { TracklistParams, TracklistInfo } from '@/types/music'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { spotifyIdToUrl } from '@/parsers'
import TracklistTable from './TracklistTable.vue'

@Component({ components: { TracklistTable } })
export default class TracklistStreamingUrlInput extends Vue {
  @Getter('campaigns/currentId') campaignId!: string
  @Action('music/LOAD_PREVIEW_FROM_SEARCH') loadPreviewFromSearch!: (p: {search_value:string}) => Promise<void>
  @Action('music/SAVE_TRACKLIST_FROM_SEARCH') saveTracklistFromSearch!: (p: {search_value:string}) => Promise<void>

  @Getter('music/tracklist') tracklist!: TracklistInfo | null
  @Getter('music/loading') loading!: boolean
  @Getter('music/tracklistPreview') tracklistPreview!: TracklistInfo | null
  @Getter('music/searchErrorMsg') searchError!: string | null

  // Type hint the form ref
  $refs!: {
    form: Vue & {
      validate: () => boolean
    }
  }

  inputId = ''

  rules = {
    inputId: [
      (v: string): true|string => !!v || 'URL is required'
    ]
  }

  formValid = true

  get savedTracklistOptions (): TracklistParams | null {
    if (!this.tracklist) {
      return null
    }
    return this.tracklist.options
  }

  get savedTracklistUrl (): string | null {
    if (!this.savedTracklistOptions) {
      return null
    }
    if (this.savedTracklistOptions.url) {
      return this.savedTracklistOptions.url
    }
    // Old tracklists don't have the url saved, but they are always spotify so this is fine as a fallback
    return spotifyIdToUrl(this.savedTracklistOptions.type, this.savedTracklistOptions.id)
  }

  get errorMessages (): string[] {
    return this.searchError ? [this.searchError] : []
  }

  get tableTracks () {
    return this.tracklistPreview?.items || this.tracklist?.items || []
  }

  @Watch('inputId')
  onInputChanged () {
    this.updateCurrentItem()
  }

  async reloadCurrentTracklist () {
    if (!this.savedTracklistUrl) {
      return
    }
    this.inputId = this.savedTracklistUrl
  }

  async updateCurrentItem () {
    await this.loadPreviewFromSearch({ search_value: this.inputId })
  }

  async submit (): Promise<void> {
    if (!this.$refs.form?.validate() || !this.tracklistPreview) {
      return
    }
    this.saveTracklistFromSearch({ search_value: this.inputId })
  }
}
