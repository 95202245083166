
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import rest from '@/rest'
import AppHelper from '@/AppHelper'
import DataTable from './DataTable.vue'
import { TableDefinition, ReportDownload } from '../types'
import DownloadButton from './DownloadButton.vue'

@Component({ components: { DataTable, DownloadButton } })
export default class RaffleWinners extends Vue {
  @Getter('apps/currentHelper') app!: AppHelper
  @Prop() url!: string
  winnerCount = 1
  winnerData: TableDefinition | null = null
  loading = false

  created () {
    this.loadWinners()
  }

  async pickWinners () {
    this.loading = true
    const url = this.app.absoluteUrl(this.url)
    try {
      const resp = await rest.post(url, { json: { count: this.winnerCount, replace_existing: true } })
      this.winnerData = await resp.json()
    } finally {
      this.loading = false
    }
  }

  async loadWinners () {
    this.loading = true
    const url = this.app.absoluteUrl(this.url)
    try {
      const resp = await rest.get(url)
      this.winnerData = await resp.json()
      this.winnerCount = this.winnerData?.rows.length || 1
    } finally {
      this.loading = false
    }
  }

  get hasDownloads (): boolean {
    return !!this.winnerData?.downloads && this.winnerData.downloads.length > 0
  }

  get downloads (): ReportDownload[] {
    const dl = this.winnerData?.downloads || []
    console.log('DOWNLOAD', dl)
    return dl
  }
}
