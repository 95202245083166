import { ActionContext } from 'vuex'

import { RootState } from '../types'
import rest from '@/rest'
import { LocaleMap } from '@/types/i18n'

interface I18nState {
  allLocales: LocaleMap
  loading: boolean
}

type I18nActionContext = ActionContext<I18nState, RootState >

const state: I18nState = {
  allLocales: {},
  loading: false
}

const mutations = {
  SET_LOADING (state: I18nState, loading: boolean) {
    state.loading = loading
  },
  SET_LOCALES (state: I18nState, data: LocaleMap) {
    state.allLocales = data
  }
}

const actions = {
  async LOAD_LOCALES (ctx: I18nActionContext) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.i18n.getAllLocales()
      ctx.commit('SET_LOCALES', data)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    allLocales: (state: I18nState) => state.allLocales
  },
  actions
}
