
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class PasswordReset extends Vue {
  @Action('users/UPDATE_USER_PASSWORD') updatePassword!: (p: { password: string; token: string }) => Promise<void>
  @Getter('users/editLoading') loading!: boolean

  password = ''
  token = ''
  isSubmit = false

  created () {
    this.token = this.$route.params.token
  }

  async submit () {
    await this.updatePassword({
      token: this.token,
      password: this.password
    })
    this.isSubmit = true
  }
}
