
import { defineComponent } from 'vue'
import { PartyServiceSearchRecord } from '@/types/partyService'

export default defineComponent({

  props: {
    item: {
      type: Object as () => PartyServiceSearchRecord,
      required: true
    }
  }

})
