import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '@/assets/global.css'
import createStore from './store'
import VueApexCharts from 'vue-apexcharts'
// @ts-ignore
import packageInfo from '../package.json'

console.info(`UMG CMS version: ${packageInfo.version}`)

Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)

new Vue({
  vuetify,
  router,
  store: createStore(),
  render: h => h(App)
}).$mount('#app')
