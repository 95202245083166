import pkceChallenge from './pkce'
import rest from '@/rest'

const CLIENT_ID = 'crm-tools'
const OAUTH_STATE_STORAGE_KEY = 'oauth_state'

interface OauthParams {
  authorizationUrl: string
  codeVerifier: string
  codeChallenge: string
}

export async function generateOAuthParams (redirectUri: string|null = null): Promise<OauthParams> {
  const authorizationUrlBase = window.location.origin + '/api/auth/oauth2/authorize'
  const finalRedirectUri = redirectUri || window.location.href
  const { code_verifier, code_challenge } = await pkceChallenge()
  const authorizeUrl = new URL(authorizationUrlBase)
  authorizeUrl.searchParams.append('client_id', CLIENT_ID)
  authorizeUrl.searchParams.append('redirect_uri', finalRedirectUri)
  authorizeUrl.searchParams.append('response_type', 'code')
  authorizeUrl.searchParams.append('code_challenge', code_challenge)
  authorizeUrl.searchParams.append('code_challenge_method', 'S256')
  authorizeUrl.searchParams.append('state', 'crm-login')

  // Store the code verifier in local storage
  window.localStorage.setItem(OAUTH_STATE_STORAGE_KEY, JSON.stringify({
    codeVerifier: code_verifier,
    redirectUri: finalRedirectUri
  }))

  return {
    authorizationUrl: authorizeUrl.toString(),
    codeVerifier: code_verifier,
    codeChallenge: code_challenge
  }
}

export async function handleCallback (): Promise<boolean> {
  const url = new URL(window.location.href)
  // Check if state param is set
  if (url.searchParams.get('state') !== 'crm-login') {
    // we are not in callback
    return false
  }
  const code = url.searchParams.get('code')
  const error = url.searchParams.get('error')
  // remove all the params from url
  const newUrl = new URL(window.location.href)
  newUrl.searchParams.delete('code')
  newUrl.searchParams.delete('error')
  newUrl.searchParams.delete('state')
  window.history.replaceState({}, '', newUrl.toString())
  if (error) {
    throw new Error(error)
  }
  if (!code) {
    throw new Error('No code in callback')
  }

  const state = window.localStorage.getItem(OAUTH_STATE_STORAGE_KEY)
  if (!state) {
    throw new Error('No oauth state in storage')
  }

  const { codeVerifier, redirectUri } = JSON.parse(state)
  window.localStorage.removeItem(OAUTH_STATE_STORAGE_KEY)

  await rest.tokenFromCode(code, redirectUri, CLIENT_ID, codeVerifier)
  return true
}
