
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { User } from '@/types/users'

@Component
export default class UserStateEdit extends Vue {
  @Prop() userId!: string
  @Getter('users/getUser') getUser!: (id: string) => User
  @Getter('users/editLoading') loading!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  availableStates = [
    {
      text: 'Pending',
      value: 'pending',
      disabled: true
    },
    {
      text: 'Approved',
      value: 'approved'
    },
    {
      text: 'Rejected',
      value: 'rejected'
    }
  ]

  async setState (state: string) {
    await this.$store.dispatch('users/UPDATE_USER_STATE', {
      id: this.userId,
      state
    })
  }

  get state (): string {
    return this.getUser(this.userId).state
  }
}
