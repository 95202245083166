

import { TracklistInfo } from '@/types/music'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { type PartialItem } from '@/types/music'

const DEFAULT_URL = 'https://backend.umg-cms.eu/assets/584980'

@Component
export default class TracklistVideoListInput extends Vue {
  @Getter('campaigns/currentId') campaignId!: string
  @Action('music/LOAD_PREVIEW_FROM_SEARCH') loadPreviewFromSearch!: (p: {partial_items: PartialItem[]}) => Promise<void>
  @Action('music/SAVE_TRACKLIST_FROM_SEARCH') saveTracklistFromSearch!: (p: {partial_items: PartialItem[]}) => Promise<void>

  @Getter('music/tracklist') tracklist!: TracklistInfo | null
  @Getter('music/loading') loading!: boolean
  @Getter('music/tracklistPreview') tracklistPreview!: TracklistInfo | null
  @Getter('music/searchErrorMsg') searchError!: string | null

  newUrl: string|null = DEFAULT_URL
  newName: string|null = null

  videoUrls: PartialItem[] = []

  async addVideo () {
    if (this.newUrl) {
      this.videoUrls.push({ id: this.newUrl, name: this.newName || null })
      this.newUrl = DEFAULT_URL
      this.newName = null
      await this.loadPreviewFromSearch({ partial_items: this.videoUrls })
    }
  }

  async deleteVideo (index: number) {
    this.videoUrls.splice(index, 1)
    await this.loadPreviewFromSearch({ partial_items: this.videoUrls })
  }

  async submit () {
    await this.saveTracklistFromSearch({ partial_items: this.videoUrls })
  }
}

