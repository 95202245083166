
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import MediaItemCard from '@/components/MediaItemCard.vue'
import { joinPath } from '@/util'

@Component({ components: { MediaItemCard } })
export default class MediaFolderCard extends Vue {
  @Prop() pathname!: string
  @Prop({ type: String, default: null }) to!: string | null
  @Action('uploads/DELETE_FOLDER') deleteFolder!: (p: string) => Promise<void>

  get title () {
    return this.pathname.split('/').pop()
  }

  async onDelete () {
    let msg = `Are you sure you want to permanently delete the folder "${this.pathname}"?`
    msg += `\nTo confirm enter the name of the folder: ${this.title}.`

    if (prompt(msg) === this.title) {
      await this.deleteFolder(joinPath('/', this.pathname))
    }
  }
}
