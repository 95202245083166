
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class PasswordReset extends Vue {
  email = ''
  isSubmit = false

  @Action('users/RESET_USER_PASSWORD') resetPassword!: (email: string) => Promise<void>
  @Getter('users/editLoading') loading!: boolean

  async submit () {
    await this.resetPassword(this.email)
    this.isSubmit = true
  }
}
