
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { CampaignData, CampaignNewIn } from '../types/campaigns'
import AppHelper from '@/AppHelper'
import SlugNameEdit from '@/components/SlugNameEdit.vue'

@Component({ components: { SlugNameEdit } })
export default class CampaignNew extends Vue {
  @Getter('apps/helpers') apps!: {[key: string]: AppHelper}
  @Action('campaigns/CREATE_NEW') createCampaign!: (data: CampaignNewIn) => Promise<CampaignData>

  name =''
  slug = ''
  appId: string | null = null
  test = false
  valid = true
  rules = {
    required: [(value: string) => !!value || 'Required']
  }

  submitLoading = false

  get appSelectItems () {
    return Object.values(this.apps).filter(a => a.topLevel).sort((a, b) => a.name.localeCompare(b.name))
  }

  get slugAround (): [string, string] {
    const app = this.apps[this.selectedApp as string]
    if (!app) {
      return ['', '']
    }
    return app.campaignPrefixSuffix
  }

  get nameAndSlug () {
    return { name: this.name, slug: this.slug }
  }

  set nameAndSlug (value) {
    this.name = value.name
    this.slug = value.slug
  }

  set selectedApp (value) {
    this.appId = value
  }

  get selectedApp () {
    return this.appId
  }

  async submit () {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }
    this.submitLoading = true
    try {
      const result = await this.createCampaign({
        name: this.name as string,
        slug: this.slug as string,
        app: this.selectedApp as string,
        test: this.test
      })
      this.$router.push({ name: 'campaignEditor', params: { id: result.id } })
    } finally {
      this.submitLoading = false
    }
  }
}
