
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { SnackbarMessage } from '@/types/snackbar'

@Component
export default class Snackbar extends Vue {
  @Action('snackbar/SHOW_MESSAGE') showMessage: () => Promise<void>
  @Action('snackbar/HIDE_MESSAGE') hideMessage: () => Promise<void>
  @Getter('snackbar/show') show: boolean
  @Getter('snackbar/message') message: SnackbarMessage

  get showing (): boolean {
    return this.show
  }

  set showing (value: boolean) {
    if (!value) {
      this.hideMessage()
    } else {
      this.showMessage()
    }
  }
}
