
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AppHelper from '@/AppHelper'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'

@Component({ components: { TimeDeltaHuman } })
export default class AppList extends Vue {
  @Getter('apps/helpers') apps!: {[key: string]: AppHelper}
  @Action('apps/RELOAD_CONFIG') reloadConfig: (appId: string) => Promise<void>

  expanded = []
  loadingApps = new Set()

  headers = [
    {
      text: 'Name',
      value: 'name',
      sortable: true
    },
    {
      text: 'id',
      value: 'id',
      sortable: true
    },
    {
      text: 'Created',
      value: 'createdAt',
      sortable: true
    },
    {
      text: 'Updated',
      value: 'updatedAt',
      sortable: true
    },

    {
      text: '',
      value: 'lastUpdateSuccess',
      sortable: false
    }

  ]

  async triggerReload (appId: string) {
    // workaround cause sets aren't reactive
    this.loadingApps = new Set(this.loadingApps.add(appId))
    try {
      await this.reloadConfig(appId)
    } finally {
      const newSet = new Set(this.loadingApps)
      newSet.delete(appId)
      this.loadingApps = newSet
    }
  }

  get appsList (): AppHelper[] {
    return Object.values(this.apps)
  }
}
