
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import CampaignList from '@/components/CampaignList.vue'

@Component({ components: { CampaignList } })
export default class Home extends Vue {
  @Getter('permissions/canCreateCampaigns') canCreateCampaigns!: boolean
}
