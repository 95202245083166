
import { Component, Prop, Vue } from 'vue-property-decorator'
import CampaignList from '@/components/CampaignList.vue'

@Component({ components: { CampaignList } })
export default class CampaignPickerDialog extends Vue {
  // Don't show children of the current campaign
  @Prop({ default: false, type: Boolean }) excludeChildrenOfCurrent!: boolean
  @Prop({ type: String, required: false, default: null }) confirmText!: string|null
  dialog = false

  get params () {
    return { include_children: true, apps: ['pre-page'] }
  }

  onPick (campaign: any) {
    if (this.confirmText && !confirm(this.confirmText)) {
      return
    }
    this.$emit('pick', campaign)
    this.dialog = false
  }
}
