
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { TokenInfo } from '../types/auth'
import { CampaignPatch } from '../types/campaigns'
import SpinnerFull from './SpinnerFull.vue'
import AppHelper from '@/AppHelper'
import MediaPickerDialog from '@/components/MediaPickerDialog.vue'
import env from '@/env'
import { Campaign } from '@/Campaign'

@Component({ components: { SpinnerFull, MediaPickerDialog } })
export default class CampaignEditor extends Vue {
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('auth/accessToken') accessToken: string | null
  @Getter('auth/tokenInfo') tokenInfo: TokenInfo
  @Getter('apps/currentHelper') app!: AppHelper
  @Action('auth/REFRESH_TOKEN_IF_NEEDED') refreshToken: () => Promise<void>
  @Action('campaigns/EDIT_CURRENT') editCampaign!: (data: CampaignPatch) => Promise<void>

  env = env

  refreshTimeout: number | null = null
  editorLoading = true
  iframeUrl!: string
  isMediaDialogOpen = false
  mediaFieldId: string|null = null
  mediaCurrentFileUrl: string|null = null
  mediaTargetWidth: number|null = null
  mediaTargetHeight: number|null = null
  //   currentFileId = ''
  //   currentFileUrl = ''

  $refs!: {
    builderFrame: HTMLIFrameElement
  }

  iframeMode = true
  editorWindow: Window
  windowPollInterval!: number

  mounted (): void {
    this.refreshTokenBeforeExpiry()
  }

  destroyed (): void {
    window.removeEventListener('message', this.onPostMessage)
    if (this.refreshTimeout !== null) {
      window.clearInterval(this.refreshTimeout)
    }
    clearInterval(this.windowPollInterval)
    if (!this.iframeMode && this.editorWindow) {
      this.editorWindow.close()
    }
  }

  @Watch('mediaCurrentFileUrl')
  onFilePicked (url: string) {
    if (!this.isMediaDialogOpen) {
      return
    }
    this.sendPostMessage({
      type: 'mediaPicked',
      url,
      currentFileId: this.mediaFieldId, // TODO: Legacy, send both until all apps are updated
      fieldId: this.mediaFieldId
    })
    this.closeMediaDialog()
  }

  closeMediaDialog () {
    this.isMediaDialogOpen = false
    this.mediaFieldId = null
    this.mediaCurrentFileUrl = null
    this.mediaTargetWidth = null
    this.mediaTargetHeight = null
  }

  @Watch('$vuetify.theme.dark')
  onDarkTheme (dark: boolean) {
    this.sendPostMessage({ type: 'theme', theme: dark === true ? 'dark' : 'light' })
  }

  @Watch('campaign', { deep: true })
  onCampaignChanged () {
    this.sendCampaignInfo('campaignInfo')
  }

  iframeLoaded (e: Event) {
    console.log('IFRAME LOADED')
    const win = (e.target as HTMLIFrameElement).contentWindow
    if (win) {
      this.editorWindow = win
    }
  }

  refreshTokenBeforeExpiry () {
    const now = +new Date()
    const expiresIn = this.tokenInfo.accessExpires - now - 60000
    console.log('Refresh token in', expiresIn / 1000, 'seconds')
    this.refreshTimeout = window.setTimeout(async () => {
      if (this.refreshTimeout === null) {
        return
      }
      await this.refreshToken()
      this.sendAccessToken()
      this.refreshTokenBeforeExpiry()
    }, expiresIn)
  }

  sendPostMessage (msg: Record<string, any>): void {
    const origin = new URL(this.iframeUrl).origin
    console.log('CMS SEND', msg.type, msg)
    if (!this.editorWindow) {
      console.warn('Editor window not available')
      return
    }
    this.editorWindow.postMessage(msg, origin)
  }

  sendAccessToken (): void {
    this.sendPostMessage({ type: 'token', token: `Bearer ${this.accessToken}` })
  }

  created (): void {
    this.iframeUrl = this.app.editorUrl(this.campaign.data.app_id || 'new')
    console.log('IFRAME URL', this.iframeUrl)
    window.addEventListener('message', this.onPostMessage)
  }

  openWindow (): void {
    this.editorLoading = true
    if (!this.iframeMode) {
      if (this.editorWindow) {
        this.editorWindow.close()
      }
      this.iframeMode = true
      return
    }
    this.iframeMode = false
    const win = window.open(this.iframeUrl)
    if (!win) {
      return
    }
    this.windowPollInterval = window.setInterval(() => {
      if (win.closed) {
        clearInterval(this.windowPollInterval)
        this.iframeMode = true
      }
    }, 400)
  }

  sendCampaignInfo (messageType: string): void {
    // TODO: Kept for legacy sdk
    this.sendPostMessage({
      type: messageType,
      campaign: {
        id: this.campaign.data.id,
        app: this.campaign.data.app,
        appId: this.campaign.data.app_id,
        url: this.app.campaignUrl(this.campaign.data.slug),
        newsletterEnabled: this.campaign.data.newsletter.enabled,
        i18n: JSON.parse(JSON.stringify(this.campaign.data.i18n))
      }
    })
    // Send version 2 with complete campaign
    this.sendPostMessage({
      type: messageType + ':v2',
      campaign: JSON.parse(JSON.stringify(this.campaign.data))
    })
  }

  async onPostMessage (event: MessageEvent): Promise<void> {
    const origin = new URL(this.iframeUrl).origin
    if (event.origin !== origin) {
      return
    }
    console.log('CMS RECEIVED', event.data, event.origin, origin)
    if (!event.source) {
      return
    }
    this.editorWindow = event.source as Window
    const data = event.data

    switch (data.type) {
      case 'ready': {
        this.sendCampaignInfo('ready')
        this.sendAccessToken()
        this.editorLoading = false
        this.onDarkTheme(this.$vuetify.theme.dark)
        break
      }
      case 'campaignSaved': {
        await this.editCampaign({ app_id: data.campaignId.toString() })
        break
      }
      case 'openMediaLibrary': {
        // TODO: Legacy, replaced by v2 message in new sdk
        this.mediaFieldId = data.currentFileId
        this.mediaCurrentFileUrl = data.currentFileUrl || ''
        this.$nextTick(() => { this.isMediaDialogOpen = true })
        break
      }
      case 'openMediaLibrary:v2': {
        this.mediaFieldId = data.fieldId
        this.mediaCurrentFileUrl = data.currentFileUrl || ''
        this.mediaTargetWidth = data.targetWidth
        this.mediaTargetHeight = data.targetHeight
        this.$nextTick(() => { this.isMediaDialogOpen = true })
        break
      }
      default: {
        break
      }
    }
  }
}
