import { ActionContext } from 'vuex'

import { PartyServiceSearchRecord, PartyServiceSearchResults } from '@/types/partyService'
import rest from '@/rest'
import { RootState } from '../types'

interface PartyServiceState {
  loading: boolean
}

type PartyServiceActionContext = ActionContext<PartyServiceState, RootState>

const state: PartyServiceState = {
  loading: false
}

const mutations = {
  SET_LOADING (state: PartyServiceState, value: boolean) {
    state.loading = value
  }
}

const actions = {
  async SEARCH (ctx: PartyServiceActionContext, p: {query: string; limit: number; offset: number}): Promise<PartyServiceSearchResults> {
    ctx.commit('SET_LOADING', true)
    try {
      return await rest.partyService.search(p)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  }
}

const getters = {
  loading: (state: PartyServiceState) => state.loading

}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
