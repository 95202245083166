
import { User } from '@/types/users'
import { Component, Vue, Prop } from 'vue-property-decorator'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'
import UserRoleEdit from '@/components/UserRoleEdit.vue'
import UserStateEdit from '@/components/UserStateEdit.vue'

@Component({
  components: {
    TimeDeltaHuman,
    UserRoleEdit,
    UserStateEdit
  }
})
export default class UserTable extends Vue {
  @Prop({ type: Array, required: true }) users!: User[];
  @Prop({ type: Boolean, default: false }) readOnly!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, required: true }) state!: 'approved' | 'pending' | 'rejected'

  get headers () {
    const ret = [
      {
        text: 'Name',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Email',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Role',
        sortable: true,
        value: 'role'
      },
      {
        text: 'State',
        sortable: false,
        value: 'state'
      },
      {
        text: 'Registered',
        sortable: true,
        value: 'registeredAt',
        sort: (a: Date, b: Date): number => a.getTime() - b.getTime()
      }
    ]
    if (this.state !== 'pending') {
      ret.push(
        {
          text: 'Approved',
          sortable: true,
          value: 'firstApprovedAt',
          sort: (a: Date|null, b: Date|null): number => (a ? a.getTime() : -1) - (b ? b.getTime() : -1)
        }
      )
    }
    return ret
  }

  get tableData () {
    return this.users.map(
      (item: User) => ({
        id: item.id,
        email: item.email,
        name: item.display_name,
        role: item.role,
        state: item.state,
        registeredAt: new Date(item.registered_at),
        firstApprovedAt: item.first_approved_at ? new Date(item.first_approved_at) : null,
        isSystem: item.is_system
      })
    )
  }
}
