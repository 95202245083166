import { ActionContext } from 'vuex'
import { RootState } from '../types'
import rest from '@/rest'
import { CampaignAccessInfo } from '@/types/permissions'

interface PermissionState {
  currentCampaignAccess: CampaignAccessInfo[]
  loading: boolean
  editLoading: boolean
}

const state: PermissionState = {
  currentCampaignAccess: [],
  loading: false,
  editLoading: false

}

type PermissionActionContext = ActionContext<PermissionState, RootState >

const mutations = {
  SET_LOADING (state: PermissionState, loading: boolean) {
    state.loading = loading
  },
  SET_EDIT_LOADING (state: PermissionState, loading: boolean) {
    state.editLoading = loading
  },
  SET_CURRENT_CAMPAIGN_ACCESS (state: PermissionState, data: CampaignAccessInfo[]) {
    state.currentCampaignAccess = data
  }
}

const actions = {
  async LOAD_CURRENT_CAMPAIGN_ACCESS (ctx: PermissionActionContext): Promise<void> {
    ctx.commit('SET_LOADING', true)
    const campaignId = ctx.rootGetters['campaigns/currentId']
    try {
      ctx.commit(
        'SET_CURRENT_CAMPAIGN_ACCESS',
        (await rest.permissions.getCampaignAccessList(campaignId)).items
      )
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },

  async REMOVE_CURRENT_CAMPAIGN_ACCESS (ctx: PermissionActionContext, userId: string): Promise<void> {
    ctx.commit('SET_LOADING', true)
    const campaignId = ctx.rootGetters['campaigns/currentId']
    try {
      await rest.permissions.removeCampaignAccess(campaignId, userId)
      await ctx.dispatch('LOAD_CURRENT_CAMPAIGN_ACCESS')
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },

  async GRANT_CURRENT_CAMPAIGN_ACCESS (ctx: PermissionActionContext, userId: string): Promise<void> {
    ctx.commit('SET_LOADING', true)
    const campaignId = ctx.rootGetters['campaigns/currentId']
    try {
      await rest.permissions.grantCampaignAccess(campaignId, userId)
      await ctx.dispatch('LOAD_CURRENT_CAMPAIGN_ACCESS')
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  }

}

const getters = {
  loading: (state: PermissionState) => state.loading,
  currentCampaignAccess: (state: PermissionState) => state.currentCampaignAccess,
  userPermissions: (_0: PermissionState, _1: any, _2: any, rootGetters: any): Set<string> => {
    return new Set(rootGetters['users/current'].permissions)
  },
  canReadObjectAccess: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('object-access-read')
  },
  canModifyObjectAccess: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('object-access-modify')
  },
  canReadUsers: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('users-read')
  },
  canDeleteCampaigns: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('campaigns-delete')
  },
  canCreateCampaigns: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('campaigns-create')
  },
  canDeleteMedia: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('media-delete')
  },
  canModifyUsers: (_: PermissionState, getters: any): boolean => {
    return getters.userPermissions.has('users-modify')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
