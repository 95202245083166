
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AppHelper from '@/AppHelper'
import { StreamingService } from '@/types/streaming_services'
import { CampaignPatch } from '@/types/campaigns'
import { Campaign } from '@/Campaign'

@Component
export default class MusicServices extends Vue {
  @Getter('apps/currentHelper') app!: AppHelper
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('campaigns/editLoading') loading!: boolean
  @Action('campaigns/EDIT_CURRENT') saveCampaign!: (data: CampaignPatch) => Promise<void>

  keyIncr = 0 // Incrementing key cause toggles need re-rendering. v-switch keeps some internal state nonsense

  get appServices (): StreamingService[] {
    return this.app.streamingServiceNames
  }

  get enabledServices (): StreamingService[] {
    return this.campaign.data.enabled_services
  }

  async toggleService (service: StreamingService) {
    let newServices = [...this.enabledServices]
    if (newServices.includes(service)) {
      newServices = newServices.filter(s => s !== service)
    } else {
      newServices.push(service)
    }

    try {
      await this.saveCampaign({ enabled_services: newServices })
    } finally {
      this.keyIncr++
    }
  }
}
