
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class ZoomableTimeseriesChart extends Vue {
  @Prop() data!: any

  optionsDefault = {
    chart: {
      type: 'area',
      background: 'transparent',
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      animations: {
        enabled: false
      }
    },
    toolbar: {
      autoSelected: 'zoom'
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      x: {
        formatter: (val: any) => new Date(val).toLocaleString()
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }

    }
  };

  themeMode= 'light'

  created () {
    this.onThemeChange(this.$vuetify.theme.dark)
  }

  get options () {
    return { ...this.optionsDefault, theme: { mode: this.themeMode }, tooltip: { ...this.optionsDefault.tooltip, theme: this.themeMode } }
  }

  @Watch('$vuetify.theme.dark')
  onThemeChange (dark: boolean) {
    this.themeMode = dark ? 'dark' : 'light'
  }
}
