
import { TracklistParams, TracklistInfo } from '@/types/music'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { spotifyIdToUrl } from '@/parsers'
import TracklistTable from './TracklistTable.vue'
import TracklistStreamingUrlInput from './TracklistStreamingUrlInput.vue'
import TracklistVideoListInput from './TracklistVideoListInput.vue'

@Component({ components: { TracklistTable, TracklistStreamingUrlInput, TracklistVideoListInput } })
export default class Tracklist extends Vue {
  @Getter('campaigns/currentId') campaignId!: string
  @Action('music/LOAD_TRACKLIST') loadTracklist!: () => Promise<void>

  @Getter('music/tracklist') tracklist!: TracklistInfo | null
  @Getter('music/loading') loading!: boolean
  @Getter('music/tracklistPreview') tracklistPreview!: TracklistInfo | null

  get savedTracklistOptions (): TracklistParams | null {
    if (!this.tracklist) {
      return null
    }
    return this.tracklist.options
  }

  get savedTracklistUrl (): string | null {
    if (!this.savedTracklistOptions) {
      return null
    }
    if (this.savedTracklistOptions.url) {
      return this.savedTracklistOptions.url
    }
    // Old tracklists don't have the url saved, but they are always spotify so this is fine as a fallback
    return spotifyIdToUrl(this.savedTracklistOptions.type, this.savedTracklistOptions.id)
  }

  async created () {
    await this.loadTracklist()
  }

  get tableTracks () {
    return this.tracklistPreview?.items || this.tracklist?.items || []
  }
}
