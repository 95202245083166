
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TableDefinition } from '../types'
import DataTable from './DataTable.vue'
import RaffleWinners from './RaffleWinners.vue'

@Component({ components: { DataTable, RaffleWinners } })
export default class RaffleWinnersTable extends Vue {
  @Prop() data!: TableDefinition

  get expanded () {
    if (this.data.all_expanded) {
      return this.data.rows
    }
  }

  get showExpand () {
    if (this.data.all_expanded) {
      return false
    }
    return true
  }
}
