
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { User } from '@/types/users'

@Component
export default class UserRoleEdit extends Vue {
  @Prop() userId!: string
  @Getter('users/getUser') getUser!: (id: string) => User
  @Getter('users/editLoading') loading!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  availableRoles = [
    {
      text: 'Admin',
      value: 'admin'
    },
    {
      text: 'Editor',
      value: 'editor'
    },
    {
      text: 'External',
      value: 'external'
    }
  ]

  async setRole (role: string) {
    await this.$store.dispatch('users/UPDATE_USER_ROLE', {
      id: this.userId,
      role
    })
  }

  get role (): string {
    return this.getUser(this.userId).role
  }
}
