
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { makeReportUrl } from '@/lib/reports'
import ReportWrapper from '@/reports'
import AppHelper from '@/AppHelper'
import { Campaign } from '@/Campaign'

@Component({ components: { ReportWrapper } })
export default class CampaignReports extends Vue {
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('apps/currentHelper') app!: AppHelper

  get reportUrl (): string|null {
    return makeReportUrl(this.campaign)
  }
}
