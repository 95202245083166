
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MessagePanel extends Vue {
  @Prop() messageId!: string
  @Prop({ type: [String, Array] }) value!: string | string[]
  @Prop({ type: Number, default: 2 }) nPlurals!: number
  @Prop({ type: Boolean, default: false }) readOnly!: boolean

  get isPlural (): boolean {
    return Array.isArray(this.value)
  }

  get valueArray (): string[] {
    return Array.isArray(this.value) ? [...this.value] : [this.value]
  }

  get pluralMessage (): string[] {
    const vals = this.valueArray
    if (!this.isPlural) {
      return vals
    }
    if (vals.length < this.nPlurals) {
      return [...vals, ...Array(this.nPlurals - vals.length).fill('')]
    }
    return vals.slice(0, this.nPlurals)
  }

  onChange (i: number, value: string) {
    const newArr = [...this.pluralMessage]
    newArr[i] = value
    const newValue = this.isPlural ? newArr : newArr[0]
    this.$emit('input', newValue)
  }
}
