
export function parseSpotifyId (urlOrId: string): string {
  let url: URL
  try {
    url = new URL(urlOrId)
  } catch (err) {
    return urlOrId
  }
  if (url.protocol === 'https:') {
    const parts = url.pathname.split('/')
    if (parts.length > 1) {
      return parts.pop() as string
    }
  } else if (url.protocol === 'spotify:') {
    const parts = url.pathname.split(':')
    if (parts.length > 1) {
      return parts.pop() as string
    }
  }
  return urlOrId
}

interface AppleStorefrontId {
  storefront: string
  id: string
}

export function parseAppleId (
  urlOrId: string,
  storefront: string | null = null
): AppleStorefrontId {
  const default_storefront = 'us'
  storefront = storefront || default_storefront
  let url: URL
  try {
    url = new URL(urlOrId)
  } catch (err) {
    return { id: urlOrId, storefront }
  }
  if (url.protocol === 'https:') {
    const parts = url.pathname.split('/').filter((v) => v.trim().length > 0)
    if (parts.length > 3) {
      return { storefront: parts[0], id: parts.pop() as string }
    }
  }
  return { id: urlOrId, storefront }
}

export function spotifyIdToUrl (
  type: string,
  id: string
): string {
  return `spotify:${type}:${id}`
}
