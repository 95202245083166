
/**
 * Wrap action function to prevent multiple
 * concurrent calls
 *
 * This will await the previous promise before starting
 * a new one.
 * Ideally should cancel the prev promise
 * but not sure how to do that atm
 */
export function lockedAction<T> (func: T): T {
  let promise: Promise<any> | null = null
  async function inner (...allArgs: any) {
    try {
      if (promise !== null) {
        try {
          await promise
        } catch (err) {
        }
      }
      // @ts-ignore
      promise = func(...allArgs)
      return await promise
    } finally {
      promise = null
    }
  }
  // @ts-ignore
  return inner
}
