export function formatMsToMinutes (ms: number): string {
  const seconds = Math.floor(ms / 1000)
  const remainder = (seconds % 60).toString().padStart(2, '0')
  const minutes = Math.floor(seconds / 60).toString().padStart(2, '0')

  return `${minutes}:${remainder}`
}

export function sleep (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function slashPrefix (path: string): string {
  // Ensure path has leading slash and no trailing slash
  return '/' + path.replace(/^\/|\/$/g, '')
}

export function joinPath (root: string, path: string): string {
  const pRoot = slashPrefix(root)
  const pPath = slashPrefix(path)
  if (pRoot === '/') {
    return pPath
  } else if (pPath === '/') {
    return pRoot
  }
  return pRoot + pPath
}

export function pathSplitLast (path: string): [string, string] {
  const splitted = path.split('/')
  return [slashPrefix(splitted.slice(0, -1).join('/')), splitted.slice(-1)[0]]
}

export function copyToClipboard (text: string): void {
  const hiddenInput: HTMLInputElement = document.createElement('input')

  hiddenInput.value = text
  hiddenInput.setAttribute('style', 'position: absolute; left: -9999px; opacity: 0.01')

  document.body.appendChild(hiddenInput)

  // if is ios
  if (window.navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange()
    range.selectNodeContents(hiddenInput)
    const selection: any = window.getSelection()

    selection.removeAllRanges()
    selection.addRange(range)
    hiddenInput.setSelectionRange(0, 999999)
  } else {
    hiddenInput.select()
  }

  document.execCommand('copy')
  document.body.removeChild(hiddenInput)
}

export function debounce<T extends (...args: any[]) => void>(
  func: T, wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null

  return function (...args: Parameters<T>) {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }
}
