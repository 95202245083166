import { UserSimple } from './users'

export const MSG_TYPE_LOCK_CAMPAIGN = 'LOCK_CAMPAIGN'
export const MSG_TYPE_UNLOCK_CAMPAIGN = 'UNLOCK_CAMPAIGN'
export const MSG_TYPE_SESSION_ID = 'SESSION_ID'
export const MSG_TYPE_CAMPAIGN_UPDATED = 'CAMPAIGN_UPDATED'

interface SocketMessageData {
  type: string
}

interface SocketMessage {
  data: SocketMessageData
}

interface StreamMessage {
  id: string
  sent_at: string
}

interface StreamMessageData extends SocketMessageData {
  session_id: string
}

interface SessionIdMessageData extends SocketMessageData {
  type: typeof MSG_TYPE_SESSION_ID
  session_id: string
}

export interface SessionIdMessage extends SocketMessage {
  data: SessionIdMessageData
}

export type MessageUserInfo = UserSimple

interface CampaignLockMessageData extends StreamMessageData {
  type: typeof MSG_TYPE_LOCK_CAMPAIGN|typeof MSG_TYPE_UNLOCK_CAMPAIGN
  user: MessageUserInfo
  campaign_id: string
}

export interface CampaignLockMessage extends StreamMessage {
  data: CampaignLockMessageData
}

interface MessageCampaign {
  id: string
  updated_at: string
}

interface CampaignUpdatedMessageData extends StreamMessageData {
  type: typeof MSG_TYPE_CAMPAIGN_UPDATED
  user: MessageUserInfo
  campaign: MessageCampaign
}

export interface CampaignUpdatedMessage extends StreamMessage {
  data: CampaignUpdatedMessageData
}

export type AnyMessage = SessionIdMessage | CampaignLockMessage | CampaignUpdatedMessage
export type AnyCampaignMessage = CampaignLockMessage | CampaignUpdatedMessage

export interface ConnectionStatus {
  connected: boolean
  loading: boolean
  retry: boolean
}
