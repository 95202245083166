
import { Component, Vue, Prop } from 'vue-property-decorator'
import MediaFileCard from '../components/MediaFileCard.vue'
import { Action } from 'vuex-class'
import { UploadFileIn, MediaFile } from '@/types/uploads'

interface FileStatus {
  file: File
  state: 'pending'|'started'|'done'
  success: boolean
  errorMessage: string | null
  result: MediaFile | null
}

@Component({ components: { MediaFileCard } })
export default class MediaUpload extends Vue {
  @Action('uploads/CREATE_NEW') createNew: (data: UploadFileIn) => Promise<MediaFile>
  @Prop({ default: '' }) folder!: string
  @Prop({ default: true }) multiUpload!: boolean

  newFile: File | null = null
  dragActive = false
  fileList: File[] = []
  uploadStatus: Record<string, FileStatus> = {}
  loading = false

  $refs: {
    fileInput: HTMLInputElement
  }

  get hasFiles (): boolean {
    return Object.keys(this.uploadStatus).length > 0
  }

  get hasRemainingFiles (): boolean {
    for (const f of Object.values(this.uploadStatus)) {
      if (!f.success) {
        return true
      }
    }
    return false
  }

  get hasErrors (): boolean {
    for (const f of Object.values(this.uploadStatus)) {
      if (f.state === 'done' && !f.success) {
        return true
      }
    }
    return false
  }

  get hasLoading (): boolean {
    if (this.loading) {
      return true
    }
    for (const f of Object.values(this.uploadStatus)) {
      if (f.state === 'started') {
        return true
      }
    }
    return false
  }

  async uploadAll () {
    this.loading = true
    for (const f of Object.values(this.uploadStatus)) {
      if (f.state === 'done' && f.success) {
        continue
      }
      await this.uploadSingleFile(f)
    }
    this.loading = false
  }

  openBrowse (e: MouseEvent) {
    if (!e.target || !(e.target instanceof HTMLElement)) {
      return
    }
    if (!e.target.dataset.dropZone) { return }
    this.$refs.fileInput.click()
  }

  browserChanged () {
    this.replaceFiles([...(this.$refs.fileInput.files || [])])
  }

  updateStatus (newStatus: FileStatus) {
    this.uploadStatus = { ...this.uploadStatus, [newStatus.file.name]: newStatus }
  }

  deleteFile (fileName: string) {
    this.$delete(this.uploadStatus, fileName)
  }

  async uploadSingleFile (data: FileStatus) {
    const f = data.file
    const status = { ...this.uploadStatus[f.name] }
    status.state = 'started'
    this.updateStatus(status)
    try {
      const resultFile = await this.createNew({ file: f, folder: this.folder })
      status.result = resultFile
      status.success = true
    } catch (err) {
      status.errorMessage = (err as Error).toString()
    } finally {
      status.state = 'done'
    }
    this.updateStatus(status)
  }

  dragOver (e: DragEvent) {
    e.preventDefault()
    this.dragActive = true
  }

  dragLeave () {
    this.dragActive = false
  }

  replaceFiles (files: File[]) {
    const newStatus: Record<string, FileStatus> = {}
    for (const f of files) {
      newStatus[f.name] = {
        file: f, state: 'pending', success: false, errorMessage: null, result: null

      }
    }
    this.uploadStatus = newStatus
  }

  drop (e: DragEvent) {
    e.preventDefault()
    const inFiles = [...(e.dataTransfer?.files || [])]
    this.replaceFiles(inFiles)
    this.dragActive = false
  }
}
