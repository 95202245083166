
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import MediaLibrary from '@/components/MediaLibrary.vue'
import { Route } from 'vue-router'
import { MediaFile } from '../types/uploads'
import { Getter, Action } from 'vuex-class'
import { joinPath } from '@/util'

@Component({ components: { MediaLibrary } })
export default class MediaPickerDialog extends Vue {
  @Prop({ default: null }) value!: string | null
  @Prop({ default: null }) label!: string | null
  @Prop() baseRouteName!: string
  @Prop({ default: true }) hasInput!: boolean
  @Prop({ default: true }) isDialogOpen!: boolean
  @Prop({ required: false, type: Number, default: null }) targetWidth!: number|null
  @Prop({ required: false, type: Number, default: null }) targetHeight!: number|null
  @Getter('campaigns/currentMediaFolder') campaignFolder!: string
  @Action('campaigns/CHANGE_CURRENT_MEDIA_FOLDER') changeCampaignFolder!: (folder: string) => Promise<void>

  @Watch('isDialogOpen')
  watchIsDialogOpen (isDialogOpen: boolean) {
    this.dialog = isDialogOpen
  }

  dialog = false
  startRoutePath = ''
  folderPath = '/'

  created () {
    this.startRoutePath = this.$route.path
    this.folderPath = '/'
  }

  onChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (newVal: Route) {
    if (this.dialog === true) {
      if (newVal.path === this.startRoutePath) {
        this.dialog = false
      }
    } else {
      if (this.$route.name !== this.baseRouteName) {
        this.startRoutePath = this.$route.path
      }
    }
    this.folderPath = newVal.params.pathMatch || '/'
  }

  @Watch('dialog')
  onOpenChange (newVal: boolean, oldVal: boolean) {
    if (newVal === false) {
      this.$emit('close')
    }

    if (newVal === oldVal) {
      return
    }

    if (newVal) {
      let newPath = ''
      newPath = joinPath(this.baseRoute, this.campaignFolder)
      if (this.$route.path !== newPath) {
        this.$router.push(newPath)
      }
    } else {
      if (this.$route.path !== this.startRoutePath) {
        this.$router.replace(this.startRoutePath)
      }
    }
  }

  get baseRoute () {
    if (!this.dialog) {
      return '/'
    }
    const resolved = this.$router.resolve(
      // @ts-ignore
      { name: this.baseRouteName, params: { ...this.$route.params, pathMatch: undefined } }
    )
    return resolved.route.path
  }

  onFilePicked (file: MediaFile) {
    this.$emit('input', file.url)
    this.changeCampaignFolder(this.folderPath)
    this.dialog = false
  }

  onClear () {
    this.$emit('input', null)
  }
}
