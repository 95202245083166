
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { joinPath } from '@/util'

@Component
export default class MediaNewFolder extends Vue {
  @Action('uploads/CREATE_FOLDER') createFolder!: (p: string) => Promise<void>
  @Getter('uploads/editLoading') loading!: boolean
  @Prop({ default: '' }) parentFolder!: string

  folderName = ''
  dialog = false

  @Watch('folderName', { immediate: true })
  onInputChange () {
    this.$nextTick(() => {
      this.folderName = this.folderName.replaceAll('/', '')
    })
  }

  async submit () {
    const pathName = joinPath(this.parentFolder, this.folderName.trim())
    await this.createFolder(pathName)
    this.folderName = ''
    this.dialog = false
  }

  onClose () {
    this.dialog = false
  }
}

