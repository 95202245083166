
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { CampaignsLoadParams } from '@/types/campaigns'
import AppHelper from '@/AppHelper'
import { makeReportUrl } from '@/lib/reports'
import CampaignsTable from '@/components/CampaignsTable.vue'
import { Campaign } from '@/Campaign'

interface Filters extends Record<string, string[]|string|boolean> {
  appItemsSelected: string[]
  testFilter: string
  myCampaignsOnly: boolean
  searchQuery: string
}

@Component({ components: { CampaignsTable } })
export default class CampaignList extends Vue {
  @Action('campaigns/LOAD_LIST') loadData: (params: CampaignsLoadParams) => Promise<void>
  @Getter('campaigns/list') data: Campaign[]
  @Getter('campaigns/loading') loading: boolean
  @Getter('apps/helpers') apps!: { [key: string]: AppHelper }
  @Getter('campaigns/current') currentCampaign!: Campaign

  @Prop({ type: Boolean, default: false }) includeChildren!: boolean
  @Prop({ type: Array, required: false, default: null }) onlyApps!: string[]|null
  @Prop({ type: Boolean, default: false }) pickerMode!: boolean
  @Prop({ type: Boolean, default: false }) excludeChildrenOfCurrent!: boolean

  makeReportUrl = makeReportUrl

  defaultFilters: Filters = {
    appItemsSelected: [],
    testFilter: 'include',
    myCampaignsOnly: false,
    searchQuery: ''
  }

  get defaultFiltersJson (): string {
    return JSON.stringify(this.defaultFilters, Object.keys(this.defaultFilters).sort())
  }

  resetFilters (): void {
    this.filters = JSON.parse(this.defaultFiltersJson)
  }

  get filtersApplied () {
    return Object.entries(this.filters).reduce((result: Partial<Filters>, [key, value]) => {
      if (JSON.stringify(value) !== JSON.stringify(this.defaultFilters[key])) {
        return { ...result, [key]: value }
      }
      return result
    }, {})
  }

  get filtersAppliedCount (): number {
    return Object.keys(this.filtersApplied).length
  }

  filters: Filters = JSON.parse(this.defaultFiltersJson)

  async created () {
    await this.loadData({
      include_children: this.includeChildren,
      apps: this.onlyApps || []
    })

    this.filters.appItemsSelected = this.appItems.map((app) => app.id)
    this.defaultFilters.appItemsSelected = this.appItems.map((app) => app.id)
    this.loadQueryFilter()
  }

  @Watch('filters.appItemsSelected')
  onAppItemsSelectedChanged (newValue: string[], oldValue: string[]) {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return
    }
    this.filters.appItemsSelected = newValue.sort()
  }

  @Watch('filters', { deep: true })
  onFiltersChanged () {
    this.setQueryFilter(this.filtersApplied)
  }

  async setQueryFilter (value: Partial<Filters>|null) {
    const newQ = { ...this.$route.query }
    if (!value) {
      delete newQ.filters
    } else {
      newQ.filters = JSON.stringify(value, Object.keys(value).sort())
    }
    /// TODO: show total items before filter
    try {
      await this.$router.replace({
        path: this.$route.path,
        hash: this.$route.hash,
        params: this.$route.params,
        query: newQ
      })
    } catch (error) {
      if (error && (error as Error).name === 'NavigationDuplicated') {
        return
      }
      throw (error)
    }
  }

  loadQueryFilter () {
    const raw = this.$route.query.filters
    if (!raw) {
      this.setQueryFilter(null)
      return
    }
    let parsedFilters: Filters | null = null
    try {
      // @ts-ignore
      parsedFilters = JSON.parse(raw)
    } catch (error) {}
    if (!parsedFilters) {
      this.setQueryFilter(null)
    }
    if (parsedFilters) {
      this.filters = { ...this.filters, ...parsedFilters }
    }
  }

  get allAppsSelected (): boolean {
    return this.filters.appItemsSelected.length === this.appItems.length
  }

  get someAppsSelected (): boolean {
    return this.filters.appItemsSelected.length > 0
  }

  get selectAllAppsIcon (): string {
    if (this.allAppsSelected) {
      return 'mdi-close-box'
    } else if (this.someAppsSelected) {
      return 'mdi-minus-box'
    }
    return 'mdi-checkbox-blank-outline'
  }

  toggleSelectAll (): void {
    if (this.allAppsSelected) {
      this.filters.appItemsSelected = []
    } else {
      this.filters.appItemsSelected = this.appItems.map(a => a.id)
    }
  }

  get appItems () {
    const ret = Object.values(this.apps).sort((a, b) => a.name.localeCompare(b.name))
    if (this.onlyApps && this.onlyApps.length > 0) {
      return ret.filter((app) => (this.onlyApps as string[]).includes(app.id))
    }
    return ret
  }

  get totalItemsCount (): number {
    return this.tableData.length
  }

  get filteredItemsCount (): number {
    return this.tableDataShown.length
  }

  get tableDataShown () {
    const filterMap: Record<string, boolean|null> = { include: null, require: true, exclude: false }
    const testFilter: boolean|null = filterMap[this.filters.testFilter]
    const search = this.filters.searchQuery.toLowerCase().trim()
    const ret = this.tableData.filter(
      (item) => (
        this.filters.appItemsSelected.includes(item.data.app) &&
          (testFilter === null || item.data.test === testFilter) && (
          this.filters.myCampaignsOnly === false || item.data.touched_by_current_user
        ) && (!search || this.matchesSearch(search, item))
      )
    )
    if (this.excludeChildrenOfCurrent && this.currentCampaign) {
      return ret.filter((item) => item.data.parent_id !== this.currentCampaign.data.id)
    }
    return ret
  }

  matchesSearch (search: string, item: Campaign) {
    return item.data.name.toLocaleLowerCase().includes(search) ||
      item.data.app.includes(search) ||
      item.data.id === search ||
      item.data.slug.includes(search)
  }

  get tableData () {
    return this.data
  }
}
