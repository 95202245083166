import { ActionContext } from 'vuex'

import { RootState, UploadState } from '../types'
import rest from '@/rest'
import { MediaFile, MediaFolder, UploadFileIn, UploadOptimizeParams } from '@/types/uploads'
import { RestError } from '@/rest/errors'
import { FolderNotFound, FileNotFound } from '@/errors'
import { pathSplitLast } from '@/util'

type UploadActionContext = ActionContext<UploadState, RootState >

const state: UploadState = {
  loading: false,
  editLoading: false,
  list: [],
  currentPath: '/',
  folder: { name: '', path: '/', subfolders: [], files: [] }

}

const mutations = {
  SET_LOADING (state: UploadState, loading: boolean) {
    state.loading = loading
  },
  SET_CURRENT_PATH (state: UploadState, value: string) {
    state.currentPath = value
  },
  SET_FOLDER (state: UploadState, value: MediaFolder) {
    state.folder = value
  },
  SET_EDIT_LOADING (state: UploadState, loading: boolean) {
    state.editLoading = loading
  },
  SET_LIST (state: UploadState, list: MediaFile[]) {
    state.list = list
  },
  ADD_TO_LIST (state: UploadState, item: MediaFile) {
    state.list.push(item)
  },
  ADD_FILE_TO_FOLDER (state: UploadState, item: MediaFile) {
    if (state.currentPath !== item.folder) {
      return
    }
    // replace the file in folder if already there
    const index = state.folder.files.findIndex((file) => file.filename === item.filename)
    if (index >= 0) {
      state.folder.files.splice(index, 1, item)
    } else {
      state.folder.files.push(item)
    }
  },
  ADD_SUBFOLDER (state: UploadState, item: string) {
    const name = item.split('/').pop()
    if (name && !state.folder.subfolders.includes(name)) {
      state.folder.subfolders.push(name)
    }
  },
  DELETE_FROM_LIST (state: UploadState, filename: string) {
    const index = state.folder.files.findIndex((item) => item.filename === filename)

    if (index >= 0) {
      state.folder.files.splice(index, 1)
    }
  },
  DELETE_FOLDER_FROM_LIST (state: UploadState, folderName: string) {
    const index = state.folder.subfolders.findIndex((item) => item === folderName)
    if (index >= 0) {
      state.folder.subfolders.splice(index, 1)
    }
  }
}

const actions = {
  async LOAD_LIST (ctx: UploadActionContext) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.uploads.getAllUploads()
      ctx.commit('SET_LIST', data)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },
  async LOAD_FOLDER (ctx: UploadActionContext, path = '/') {
    ctx.commit('SET_CURRENT_PATH', path)
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.uploads.getFolder(path)
      if (path === ctx.state.currentPath) {
        ctx.commit('SET_FOLDER', data)
      }
    } catch (err) {
      if (err instanceof RestError && err.status === 404) {
        throw (new FolderNotFound(path))
      } else {
        throw (err)
      }
    } finally {
      if (path === ctx.state.currentPath) {
        ctx.commit('SET_LOADING', false)
      }
    }
  },
  async LOAD_FOLDER_BY_FILE_URL (ctx: UploadActionContext, url: string) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.uploads.getFolder(url, true)
      ctx.commit('SET_FOLDER', data)
      ctx.commit('SET_CURRENT_PATH', data.path)
    } catch (err) {
      if (err instanceof RestError && err.status === 404) {
        throw (new FileNotFound(url))
      } else {
        throw (err)
      }
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },
  async CREATE_NEW (ctx: UploadActionContext, data: UploadFileIn): Promise<MediaFile> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const result = await rest.uploads.createUpload(data)
      ctx.commit('ADD_FILE_TO_FOLDER', result)
      return result
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async OPTIMIZE_FILE (
    ctx: UploadActionContext, payload: {filename: string; params?: UploadOptimizeParams}
  ): Promise<MediaFile> {
    ctx.commit('SET_EDIT_LOADING', true)
    console.log(payload)
    try {
      const resultWithOptimized = await rest.uploads.optimizeUpload(payload.filename, payload.params || {})
      ctx.commit('ADD_FILE_TO_FOLDER', resultWithOptimized)
      return resultWithOptimized
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async DELETE (ctx: UploadActionContext, filename: string): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.uploads.deleteUpload(filename)
      ctx.commit('DELETE_FROM_LIST', filename)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async DELETE_FOLDER (ctx: UploadActionContext, pathname: string): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.uploads.deleteFolder(pathname)
      const [root, name] = pathSplitLast(pathname)
      if (ctx.state.folder.path === root) {
        ctx.commit('DELETE_FOLDER_FROM_LIST', name)
      }
      ctx.dispatch(
        'snackbar/SHOW_MESSAGE',
        { content: `Folder ${pathname} deleted!`, color: 'success' },
        { root: true }
      )
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },

  async CREATE_FOLDER (ctx: UploadActionContext, pathname: string): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.uploads.createFolder(pathname)
      ctx.commit('ADD_SUBFOLDER', pathname)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    list: (state: UploadState) => state.list,
    folder: (state: UploadState) => state.folder,
    editLoading: (state: UploadState) => state.editLoading,
    loading: (state: UploadState) => state.loading
  },
  actions
}
