
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { AppError } from './types/error'
import Snackbar from '@/components/Snackbar.vue'
import { SnackbarMessage } from '@/types/snackbar'

@Component({ components: { Snackbar } })
export default class App extends Vue {
  @Action('auth/INIT') initAuth!: () => Promise<void>
  @Action('snackbar/SHOW_MESSAGE') showMessage: (msg: SnackbarMessage) => Promise<void>

  async created () {
    // Get dark mode setting from browser or localstorage
    const browserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const storageThemeSaved = localStorage.getItem('dark-mode')
    if (storageThemeSaved) {
      this.$vuetify.theme.dark = storageThemeSaved === 'true'
    } else {
      this.$vuetify.theme.dark = browserDarkMode
    }

    await this.initAuth()
    Vue.config.errorHandler = this.handleError
    window.addEventListener('unhandledrejection', (e) => { this.handleError(e.reason) })
  }

  handleError (err: AppError) {
    console.error('err', err)
    this.showMessage({ content: err.toString(), color: 'error', timeout: -1 })
    if (err.name === 'Unauthorized') {
      if (this.$route.name === 'login') {
        return
      }
      this.$router.replace({
        name: 'login',
        query: {
          next: this.$route.fullPath
        }
      })
    }
  }
}
