import { errorFromResponse } from './errors'
import { TokenInfo } from '@/types/auth'
import { Apps, Users, Campaigns, Uploads, I18n, Permissions, Spotify, Apple, Music, TokenGate, UserSettings, PartyService } from './modules'
import { Socket } from './socket'

interface RequestOptions {
  includeToken?: boolean
  params?: {[key: string]: any}
  json?: any
  body?: any
  headers?: {[key: string]: string}
  includeSessionId?: boolean
}

type SetTokenInfo = (info: TokenInfo) => void
type GetTokenInfo = () => TokenInfo | null

interface ResponseTokenInfo {
  value: string
  expires_in: number
}

interface TokenResponse {
  access_token: ResponseTokenInfo
  refresh_token: ResponseTokenInfo

}

export default class RestClient {
  private setTokenInfo: SetTokenInfo
  private getTokenInfo: GetTokenInfo
  public apps: Apps
  public users: Users
  public campaigns: Campaigns
  public uploads: Uploads
  public socket: Socket
  public i18n: I18n
  public spotify: Spotify
  public apple: Apple
  public permissions: Permissions
  public music: Music
  public tokenGate: TokenGate
  public userSettings: UserSettings
  public partyService: PartyService
  public sessionId: string|null = null

  constructor () {
    this.apps = new Apps(this)
    this.users = new Users(this)
    this.campaigns = new Campaigns(this)
    this.uploads = new Uploads(this)
    this.i18n = new I18n(this)
    this.spotify = new Spotify(this)
    this.apple = new Apple(this)
    this.permissions = new Permissions(this)
    this.music = new Music(this)
    this.tokenGate = new TokenGate(this)
    this.userSettings = new UserSettings(this)
    this.partyService = new PartyService(this)
    this.socket = new Socket(this)
  }

  initAuth (
    getTokenInfo: GetTokenInfo,
    setTokenInfo: SetTokenInfo
  ) {
    // Set callbacks for get/set token in store
    this.getTokenInfo = getTokenInfo
    this.setTokenInfo = setTokenInfo
  }

  get host () {
    return window.location.origin
  }

  private async getAuthorizationHeader (): Promise<string> {
    return 'Bearer ' + await this.getAccessToken()
  }

  public async getAccessToken (): Promise<string> {
    const tokenInfo = this.getTokenInfo()
    if (!tokenInfo) {
      return ''
    }
    if (tokenInfo.accessExpires > +new Date() + 30000) {
      // access token is still valid
      return tokenInfo.accessToken
    }
    if (tokenInfo.refreshExpires < +new Date() + 30000) {
      // refresh token expired/expires soon
      return ''
    }
    await this.refreshToken(tokenInfo.refreshToken)
    const newTokenInfo = this.getTokenInfo()
    if (newTokenInfo) {
      return newTokenInfo.accessToken
    }
    return ''
  }

  private setTokenFromResponse (json: TokenResponse): void {
    this.setTokenInfo(
      {
        refreshToken: json.refresh_token.value,
        refreshExpires: +new Date() + (json.refresh_token.expires_in * 1000),
        // refreshExpires: new Date(json.refresh_token.expires_at).getTime(),
        accessToken: json.access_token.value,
        accessExpires: +new Date() + (json.access_token.expires_in * 1000)
        // accessExpires: new Date(json.access_token.expires_at).getTime()
      }
    )
  }

  async request (method: string, url: string, options: RequestOptions = {}): Promise<Response> {
    const urlObj = new URL(url, this.host)
    const fetchOptions: any = { method: method, headers: {} }
    if (options.json) {
      fetchOptions.body = JSON.stringify(options.json)
      fetchOptions.headers['Content-Type'] = 'application/json'
    } else if (options.body) {
      fetchOptions.body = options.body
    }
    if (options.params) {
      for (const [key, value] of Object.entries(options.params)) {
        // if value is an array, append multiple times
        if (Array.isArray(value)) {
          for (const v of value) {
            urlObj.searchParams.append(key, v)
          }
        } else {
          urlObj.searchParams.append(key, value)
        }
      }
    }
    if (options.includeToken === true || options.includeToken === undefined) {
      fetchOptions.headers.Authorization = await this.getAuthorizationHeader()
    }
    if (options.includeSessionId === true || options.includeSessionId === undefined) {
      if (this.sessionId) {
        fetchOptions.headers['umg-cms-session-id'] = this.sessionId
      }
    }
    fetchOptions.headers = { ...fetchOptions.headers, ...(options.headers || {}) }

    const resp = await fetch(urlObj.toString(), fetchOptions)
    const error = await errorFromResponse(resp)
    if (error !== null) {
      throw error
    }
    return resp
  }

  async get (url: string, options: RequestOptions = {}): Promise<Response> {
    return await this.request('GET', url, options)
  }

  async post (url: string, options: RequestOptions = {}): Promise<Response> {
    return await this.request('POST', url, options)
  }

  async put (url: string, options: RequestOptions = {}): Promise<Response> {
    return await this.request('PUT', url, options)
  }

  async delete (url: string, options: RequestOptions = {}): Promise<Response> {
    return await this.request('DELETE', url, options)
  }

  async patch (url: string, options: RequestOptions = {}): Promise<Response> {
    return await this.request('PATCH', url, options)
  }

  async login (email: string, password: string): Promise<void> {
    const resp = await this.post('/api/auth/jwt/session', {
      json: { email, password },
      includeToken: false
    })
    this.setTokenFromResponse(await resp.json())
  }

  async logout (): Promise<void> {
    await this.post('/api/auth/oauth2/logout', {
      includeToken: false,
      json: { refresh_token: this.getTokenInfo()?.refreshToken || '' }
    })
    this.setTokenInfo({
      refreshToken: '',
      refreshExpires: 0,
      accessToken: '',
      accessExpires: 0
    })
  }

  async refreshToken (token: string): Promise<void> {
    const form = new FormData()
    form.append('refresh_token', token)
    form.append('grant_type', 'refresh_token')
    form.append('client_id', 'crm-tools') // TODO: Hardcoded client_id
    const resp = await this.post('/api/auth/oauth2/refresh', {
      includeToken: false,
      body: form
    })
    this.setTokenFromResponse((await resp.json()).session)
  }

  async tokenFromCode (code: string, redirectUri: string, clientId: string, codeVerifier: string): Promise<void> {
    // send oauth2 token request
    const form = new FormData()
    form.append('grant_type', 'authorization_code')
    form.append('code', code)
    form.append('redirect_uri', redirectUri)
    form.append('client_id', clientId)
    form.append('code_verifier', codeVerifier)
    const resp = await this.post('/api/auth/oauth2/token', {
      includeToken: false,
      body: form
    })
    this.setTokenFromResponse((await resp.json()).session)
  }
}
