
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { CampaignNewIn, CampaignPatch, CampaignClonePayload } from '@/types/campaigns'
import SaveChangesBar from '@/components/SaveChangesBar.vue'
import { TokenGateTestAccount } from '@/types/token_gate'
import { copyToClipboard } from '@/util'
import { SnackbarMessage } from '@/types/snackbar'
import CampaignPickerDialog from '@/components/CampaignPickerDialog.vue'
import { Campaign } from '@/Campaign'

@Component({ components: { SaveChangesBar, CampaignPickerDialog } })
export default class CampaignTokenGate extends Vue {
  tokenGateEnabled = false // TODO: in store, campaign property
  twelveXTwelveProductId: string|null = null
  loading = false
  unsavedChanges = false
  watcherOn = true

  @Action('campaigns/CREATE_NEW') createCampaign!: (data: CampaignNewIn) => Promise<Campaign>
  @Action('campaigns/EDIT_CURRENT') editCurrent!: (data: CampaignPatch) => Promise<void>
  @Getter('campaigns/currentChildren') currentChildren!: Campaign[]
  @Getter('campaigns/current') currentCampaign!: Campaign
  @Getter('campaigns/tokenGateTestAccounts') tokenGateTestAccounts!: TokenGateTestAccount[]
  @Action('snackbar/SHOW_MESSAGE') showMessage: (message: SnackbarMessage) => void
  @Action('campaigns/CLONE') cloneCampaign!: (payload: CampaignClonePayload) => Promise<Campaign>

  async onPickPrePage (campaign: Campaign) {
    await this.cloneCampaign({
      id: campaign.data.id,
      options: {
        parent_id: this.currentCampaign.data.id,
        destination_id: this.firstPrePageCampaign?.data.id || null
      },
      successMessage: 'Pre-page updated successfully'
    })
  }

  copyText (text: string): void {
    copyToClipboard(text)
    this.showMessage({
      content: `Copied "${text}" to clipboard`,
      timeout: 2000

    })
  }

  created () {
    this.watcherOn = false
    this.tokenGateEnabled = this.currentCampaign.data.token_gate.enabled
    this.twelveXTwelveProductId = this.currentCampaign.data.token_gate.product_id
    this.$nextTick(() => { this.watcherOn = true })
  }

  @Watch('tokenGateEnabled')
  async onTokenGateEnabledChange (value: boolean, oldValue: boolean) {
    if (!this.watcherOn) return
    if (value && !oldValue) {
      await this.ensurePrePageCampaign()
    }
    this.unsavedChanges = true
  }

  @Watch('twelveXTwelveProductId')
  onTwelveXTwelveProductIdChange () {
    if (!this.watcherOn) return
    this.unsavedChanges = true
  }

  get prePageCampaigns (): Campaign[] {
    // TODO: Should this be called pre-page? Token gate is the locking mechanism, pre-page is one form of gateway.
    return this.currentChildren.filter(c => c.data.app === 'pre-page')
  }

  get firstPrePageCampaign (): Campaign | null {
    return this.prePageCampaigns[0] || null
  }

  async ensurePrePageCampaign () {
    if (this.prePageCampaigns.length > 0) {
      return
    }
    this.loading = true
    try {
      await this.createCampaign({
        app: 'pre-page',
        name: this.currentCampaign.data.name + ' Pre-Page',
        parent_id: this.currentCampaign.data.id,
        test: this.currentCampaign.data.test
      })
    } catch (e) {
      this.tokenGateEnabled = false
      throw e
    } finally {
      this.loading = false
    }
  }

  async saveChanges () {
    await this.editCurrent({
      token_gate: {
        enabled: this.tokenGateEnabled,
        product_id: this.twelveXTwelveProductId,
        provider: 'twelve_x_twelve'
      }
    })
    this.unsavedChanges = false
  }
}

