
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import slugify from 'slugify'

interface Values extends Record<string, string> {
  name: string
  slug: string
}

@Component
export default class SlugNameEdit extends Vue {
  @Prop({ type: Object, default: () => ({ name: 'Name', slug: 'Slug' }) }) labels!: Values
  @Prop({ type: String, default: '' }) slugPrefix!: string
  @Prop({ type: String, default: '' }) slugSuffix!: string
  @Prop({ type: Object, default: () => ({ name: '', slug: '' }) }) value!: Values
  @Prop({ type: Boolean, default: true }) slugLink!: boolean
  @Prop({ type: Boolean, default: true }) slugEditable!: boolean
  editingSlug = false
  manualSlug = true
  slugInputInner = ''

  mounted () {
    this.manualSlug = !!this.value.slug
    this.slugInput = this.value.slug
  }

  @Watch('value', { deep: true })
  onValueChanged (newValue: Values) {
    if (newValue.slug === this.slugInput) {
      return
    }
    this.slugInput = newValue.slug
  }

  toggleSlugEdit (): void {
    if (!this.editingSlug) {
      this.editingSlug = true
      const el = (this.$refs.slugTextField as any).$el as HTMLElement
      this.$nextTick(() => {
        window.setTimeout(() => {
          const inputEl = el.querySelector('input')
          if (!inputEl) {
            return
          }
          inputEl.select()
        }, 0)
      })
      this.$emit('slugEditToggled', true)
    } else {
      this.slugEditOff()
    }
  }

  slugEditOff (): void {
    this.editingSlug = false
    this.$emit('slugEditToggled', false)
    this.updateSlug(this.slugInput)
  }

  set slugInput (value) {
    this.slugInputInner = slugify(value, { strict: true, lower: true })
  }

  get slugInput () {
    return this.slugInputInner
  }

  get slugButtonIcon (): string {
    return this.editingSlug ? 'mdi-check' : 'mdi-pen'
  }

  get fullSlug (): string {
    return this.slugPrefix + this.value.slug + this.slugSuffix
  }

  updateName (value: string): void {
    this.$emit('input', { name: value, slug: this.manualSlug ? this.slugInput || this.makeSlug(value) : this.makeSlug(value) })
  }

  updateSlug (value: string): void {
    if (!value) {
      this.manualSlug = false
    }
    this.$emit('input', { slug: this.slugInput, name: this.value.name })
  }

  makeSlug (value: string): string {
    return slugify(value, { lower: true, strict: true })
  }
}
