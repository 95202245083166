
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MediaFile, MediaFolder, UploadOptimizeParams } from '@/types/uploads'
import MediaFileCard from '@/components/MediaFileCard.vue'
import MediaUpload from '@/components/MediaUpload.vue'
import MediaInfoPane from '@/components/MediaInfoPane.vue'
import MediaNewFolder from '@/components/MediaNewFolder.vue'
import MediaFolderCard from '@/components/MediaFolderCard.vue'
import { Action, Getter } from 'vuex-class'
import { joinPath, slashPrefix } from '@/util'
import { FolderNotFound, FileNotFound } from '@/errors'

@Component({ components: { MediaFileCard, MediaUpload, MediaInfoPane, MediaNewFolder, MediaFolderCard } })
export default class MediaLibrary extends Vue {
  @Action('uploads/LOAD_LIST') loadData: () => Promise<void>
  @Getter('uploads/list') files!: MediaFile[]
  @Getter('uploads/folder') folder!: MediaFolder
  @Getter('uploads/loading') loading!: boolean
  @Action('uploads/LOAD_FOLDER') loadFolder: (path: string) => Promise<void>
  @Action('uploads/LOAD_FOLDER_BY_FILE_URL') loadFolderByUrl: (path: string) => Promise<void>
  @Action('uploads/OPTIMIZE_FILE') optimizeFile!: (
    payload: { filename: string; params: UploadOptimizeParams }
  ) => Promise<MediaFile>

  @Prop({ type: Boolean, default: false }) pickerMode!: boolean
  @Prop({ default: '' }) currentFileUrl!: string
  @Prop({ default: '/' }) folderPath!: string
  @Prop({ default: '/media' }) baseRoute!: string
  @Prop({ required: false, type: Number }) targetWidth!: number
  @Prop({ required: false, type: Number }) targetHeight!: number

  currentFile: MediaFile | null = null
  searchQuery = ''
  sortBySelectedIndex = 0
  sortDirectionSelectedIndex = 1
  sortOptions = [
    ['created_at', 'Created At'],
    ['size_bytes', 'Size'],
    ['type', 'Type'],
    ['mimetype', ' Mimetype'],
    ['dimensions', 'Dimensions']
  ]

  sortDirections = [
    'asc',
    'desc'
  ]

  async created () {
    if (this.currentFileUrl) {
      try {
        await this.loadFolderByUrl(this.currentFileUrl)
        const newPath = this.makeFolderLink(this.folder.path)
        if (newPath !== this.$route.path && this.folder.path !== this.currentPath) {
          this.$router.replace(newPath)
        }
      } catch (err) {
        if (err instanceof FileNotFound) {
          await this.loadCurrentFolder()
        }
        throw (err)
      }
    } else {
      await this.loadCurrentFolder()
      return
    }

    // Find the current file from url out of all files and their optimized versions in the folder
    for (const file of this.folder.files) {
      if (file.url === this.currentFileUrl) {
        this.currentFile = file
        return
      }
      for (const optimized of file.optimized_versions) {
        if (optimized.url === this.currentFileUrl) {
          this.currentFile = file
          return
        }
      }
    }
  }

  get currentPath () {
    return slashPrefix(this.folderPath)
  }

  async loadCurrentFolder () {
    try {
      await this.loadFolder(this.currentPath)
    } catch (err) {
      if (err instanceof FolderNotFound) {
        this.$router.replace(this.baseRoute)
      } else {
        throw (err)
      }
    }
  }

  @Watch('folderPath')
  onFolderPathChange (newVal: string, oldVal: string) {
    if (oldVal === newVal) {
      return
    }
    this.loadCurrentFolder()
  }

  fileClicked (e: MediaFile) {
    this.currentFile = e
  }

  get breadcrumbs () {
    const p = this.currentPath
    const parts = p.split('/')
    let lastPart = ''
    const result = []
    for (const part of parts) {
      if (lastPart && !part) {
        continue
      }
      lastPart = joinPath(lastPart, '/' + part)
      result.push({
        to: joinPath(this.baseRoute, lastPart),
        text: lastPart === '/' ? 'Home' : part,
        exact: true

      })
    }
    return result
  }

  makeFolderLink (name: string) {
    const newPath = joinPath(this.currentPath, name)
    return joinPath(this.baseRoute, newPath)
  }

  get filesMapped (): MediaFile[] {
    return this.folder.files.map((file) => ({
      ...file,
      dimensions: file.width * file.height
    }))
  }

  get filesFiltered (): MediaFile[] {
    const q = this.searchQuery.toLowerCase()
    if (!q) {
      return this.filesMapped
    }
    return this.filesMapped.filter(
      (file) => file.filename.toLowerCase().includes(q)
    )
  }

  get filesSorted (): MediaFile[] {
    const sortIndex = this.sortDirection === 'asc' ? -1 : 1

    return this.filesFiltered.sort((a, b) =>
      (a[this.sortBy] < b[this.sortBy]) ? sortIndex : ((a[this.sortBy] > b[this.sortBy]) ? -sortIndex : 0)
    )
  }

  get subfoldersFiltered (): string[] {
    const q = this.searchQuery.toLowerCase()
    if (!q) {
      return this.folder.subfolders
    }
    return this.folder.subfolders.filter(
      f => f.toLowerCase().includes(q)
    )
  }

  get sortBy (): string {
    return this.sortOptions[this.sortBySelectedIndex][0]
  }

  get sortDirection (): string {
    return this.sortDirections[this.sortDirectionSelectedIndex]
  }

  onAfterUpload (file: MediaFile) {
    this.currentFile = file
    if (this.pickerMode) {
      this.onFilePicked(file)
    }
  }

  onFilePicked (file: MediaFile) {
    this.$emit('file-picked', file)
  }

  async optimizeCurrentFile (params: UploadOptimizeParams) {
    if (this.currentFile) {
      const payload = { filename: this.currentFile.filename, params }
      const newFile = await this.optimizeFile(payload)
      // Current file is updated with new optimized file list
      this.currentFile = newFile
    }
  }
}
