interface RoleInfo {
  title: string
  rank: number
  key: string
}

export const rolesMap: {[key: string]: RoleInfo} = {
  'users-read': {
    key: 'users-read',
    title: 'View user list',
    rank: 1
  },
  'users-modify': {
    key: 'users-modify',
    title: 'Modify user list',
    rank: 0
  },
  'campaigns-read': {
    key: 'campaigns-read',
    title: 'View campaigns',
    rank: 2

  },
  'campaigns-modify': {
    key: 'campaigns-modify',
    title: 'Edit campaigns',
    rank: 1
  },
  'consumer-data-read': {
    key: 'consumer-data-read',
    title: 'View consumer data',
    rank: 0
  },
  'consumer-data-modify': {
    key: 'consumer-data-modify',
    title: 'Edit consumer data',
    rank: 0
  }
}

export const allRoles = Object.values(rolesMap)

export function sortedRoles (roles: string[]): string[] {
  return [...roles].sort(
    (a: string, b: string) => rolesMap[a].rank - rolesMap[b].rank
  )
}

export function getRoleInfoList (roles: string[]): RoleInfo[] {
  return roles.map(r => rolesMap[r])
}

export const UNLIMITED_ROLES = ['editor', 'admin']
