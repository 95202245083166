
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CampaignFollowItem } from '@/types/campaigns'
import FollowItem from './FollowItem.vue'
import rest from '@/rest'

@Component({ components: { FollowItem } })
export default class StreamingFollow extends Vue {
  @Prop({ required: true, type: Array }) value!: CampaignFollowItem[]
  selectedService = 'spotify'
  selectedType = 'playlist'
  inputId = ''
  currentItem: CampaignFollowItem | null = null
  currentItemPost: CampaignFollowItem | null = null

  currentItemFound () {
    this.currentItemPost = this.currentItem
  }

  get textLabel (): string {
    return `Enter ${this.selectedService} ${this.selectedType} ID or URL`
  }

  async searchCurrent () {
    this.currentItem = null
    this.currentItemPost = null
    if (!this.inputId) {
      return
    }
    const item = await rest.music.findItemFromService(this.inputId)
    this.currentItem = item
    this.currentItemFound()
    //
    //     this.currentItem = { id: this.inputId, service: this.selectedService, type: this.selectedType }
  }

  addItem () {
    const newItem = { ...this.currentItemPost }
    const newValue = [...this.value, newItem]
    this.$emit('input', newValue)
    this.inputId = ''
    this.currentItem = this.currentItemPost = null
  }

  removeItem (index: number) {
    const newValue = this.value.filter((_, i) => i !== index)
    this.$emit('input', newValue)
  }
}
