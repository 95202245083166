
import { Component, Vue } from 'vue-property-decorator'
import { copyToClipboard } from '@/util'
import { Action } from 'vuex-class'
import { SnackbarMessage } from '@/types/snackbar'

@Component
export default class UserRegisterLink extends Vue {
  @Action('snackbar/SHOW_MESSAGE') showMessage!: (data: SnackbarMessage) => void
  get absoluteUrl (): string {
    return new URL(this.$router.resolve({ name: 'register' }).href, window.location.origin).toString()
  }

  copyUrl () {
    copyToClipboard(this.absoluteUrl)
    this.showMessage({ content: 'Copied to clipboard!', timeout: 2000 })
  }
}
