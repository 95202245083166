
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MediaFile, OptimizedMediaFile } from '@/types/uploads'
import { Action, Getter } from 'vuex-class'
import { copyToClipboard } from '@/util'

@Component
export default class MediaInfoPane extends Vue {
  @Prop() currentFile!: MediaFile | null
  @Prop({ type: Boolean, default: false }) pickerMode!: boolean
  @Prop({ type: Number, required: false, default: null }) targetWidth!: number|null
  @Prop({ type: Number, required: false, default: null }) targetHeight!: number|null

  @Action('uploads/DELETE') delete!: (filename: string) => Promise<void>
  @Action('uploads/OPTIMIZE_FILE') optimizeFile!: (filename: string) => Promise<MediaFile>
  @Getter('permissions/canDeleteMedia') canDeleteMedia!: boolean
  @Getter('uploads/editLoading') editLoading!: boolean

  isCopied = false
  currentSelectedUrl: string|null = null

  onFilePicked () {
    if (this.pickerMode) {
      this.$emit('file-picked', this.currentSelectedFile)
    } else {
      this.copyUrl()
    }
  }

  @Watch('currentFile')
  onCurrentFileChange () {
    if (this.bestOptimizedFile) {
      this.currentSelectedUrl = this.bestOptimizedFile.url
    } else {
      this.currentSelectedUrl = this.currentFile?.url || null
    }
  }

  get versionsItems () {
    if (!this.currentFile) {
      return []
    }
    const bestFile = this.bestOptimizedFile
    const ret = []
    ret.push({
      text: `Original (${this.currentFile.width}x${this.currentFile.height})`,
      value: this.currentFile.url
    })
    if (bestFile) {
      ret.push({
        text: `Optimized (${this.dimensionsLabel(bestFile.width, bestFile.height)}) (recommended)`,
        value: bestFile.url
      })
    }
    for (const opt of this.currentFile.optimized_versions) {
      if (opt === bestFile) {
        continue
      }
      ret.push({
        text: `Optimized (${this.dimensionsLabel(opt.width, opt.height)})`,
        value: opt.url
      })
    }
    return ret
  }

  get currentSelectedFile (): MediaFile|OptimizedMediaFile|null {
    if (!this.currentSelectedUrl || !this.currentFile) {
      return null
    }
    if (this.currentSelectedUrl === this.currentFile.url) {
      return this.currentFile
    }
    return this.currentFile?.optimized_versions.find((v) => v.url === this.currentSelectedUrl) || null
  }

  get currentFileLines () {
    if (!this.currentSelectedFile || !this.currentFile) {
      return []
    }
    const f = this.currentSelectedFile

    return [
      [
        'Type', f.type
      ],
      [
        'Filename', f.filename
      ],
      [
        'Uploaded', new Date(this.currentFile.created_at).toLocaleString()
      ],
      [
        'MimeType', f.mimetype
      ],
      [
        'Size', f.size_human
      ],
      [
        'Dimensions', `${f.width}x${f.height}px`
      ]
    ]
  }

  dimensionsLabel (width: number|null, height: number|null): string {
    if (width && height) {
      return `${width}x${height} px`
    } else if (width) {
      return `${width} px width`
    } else if (height) {
      return `${height} px height`
    } else {
      return ''
    }
  }

  get optimizeLabel () {
    return this.dimensionsLabel(this.targetWidth, this.targetHeight)
  }

  get canBeOptimized (): boolean {
    // TODO: Optimizable types should be configured somewhere
    return this.currentFile?.type === 'image' && ['image/jpeg', 'image/png'].includes(this.currentFile.mimetype)
  }

  get bestOptimizedFile (): OptimizedMediaFile | null {
    if (!this.currentFile) {
      return null
    }

    for (const opt of this.currentFile.optimized_versions) {
      if (opt.target_width === this.targetWidth && opt.target_height === this.targetHeight) {
        return opt
      }
    }
    return null
  }

  copyUrl () {
    copyToClipboard(this.currentSelectedFile?.url || '')
    this.isCopied = true

    setTimeout(() => {
      this.isCopied = false
    }, 2000)
  }

  async onDelete () {
    const isConfirmed = confirm(`Are you sure you want to delete this ${this.currentFile?.type || ''}`)

    if (isConfirmed) {
      await this.delete(this.currentFile?.filename || '')
      this.$emit('on-delete')
    }
  }
}
