import { ActionContext } from 'vuex'

import { PersonalAccessTokenPrivate, type PersonalAccessTokenStored } from '@/types/user_settings'
import rest from '@/rest'
import { RootState } from '../types'

interface UserSettingsState {
  personalAccessTokens: PersonalAccessTokenStored[]
  loading: boolean
}

type UserSettingsActionContext = ActionContext<UserSettingsState, RootState>

const state: UserSettingsState = {
  personalAccessTokens: [],
  loading: true
}

const mutations = {
  SET_LOADING (state: UserSettingsState, value: boolean) {
    state.loading = value
  },
  SET_PERSONAL_ACCESS_TOKENS (state: UserSettingsState, tokens: PersonalAccessTokenStored[]) {
    state.personalAccessTokens = tokens
  },
  ADD_PERSONAL_ACCESS_TOKEN (state: UserSettingsState, token: PersonalAccessTokenStored) {
    state.personalAccessTokens = [...state.personalAccessTokens, token]
  },
  DELETE_PERSONAL_ACCESS_TOKEN (state: UserSettingsState, id: string) {
    state.personalAccessTokens = state.personalAccessTokens.filter(t => t.id !== id)
  }

}

const actions = {
  async LOAD_PERSONAL_ACCESS_TOKENS (ctx: UserSettingsActionContext) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.userSettings.getPersonalAccessTokens()
      ctx.commit('SET_PERSONAL_ACCESS_TOKENS', data.items)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },
  async CREATE_PERSONAL_ACCESS_TOKEN (ctx: UserSettingsActionContext, data: { token_name: string; permissions: string[] }): Promise<PersonalAccessTokenPrivate> {
    ctx.commit('SET_LOADING', true)
    try {
      const newToken = await rest.userSettings.createPersonalAccessToken(data)
      ctx.commit('ADD_PERSONAL_ACCESS_TOKEN', newToken)
      return newToken
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },
  async DELETE_PERSONAL_ACCESS_TOKEN (ctx: UserSettingsActionContext, id: string) {
    ctx.commit('SET_LOADING', true)
    try {
      await rest.userSettings.deletePersonalAccessToken(id)
      ctx.commit('DELETE_PERSONAL_ACCESS_TOKEN', id)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  }
}

const getters = {
  personalAccessTokens: (state: UserSettingsState) => state.personalAccessTokens,
  loading: (state: UserSettingsState) => state.loading
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
