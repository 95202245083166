import { ActionContext } from 'vuex'

import { RootState, AppState } from '../types'
import rest from '@/rest'
import { App, AppIn } from '@/types/apps'
import AppHelper from '@/AppHelper'
import { Campaign } from '@/Campaign'

type AppActionContext = ActionContext<AppState, RootState >

const state: AppState = {
  loading: true,
  apps: {},
  currentID: null,
  editLoading: false
}

const mutations = {
  SET_LOADING (state: AppState, loading: boolean) {
    state.loading = loading
  },
  SET_EDIT_LOADING (state: AppState, loading: boolean) {
    state.editLoading = loading
  },
  SET_CONFIGS (state: AppState, data: App[]) {
    state.apps = Object.fromEntries(data.map(item => [item.id, item]))
  },
  REPLACE (state: AppState, app: App) {
    state.apps = { ...state.apps, [app.id]: app }
  }
}

const actions = {
  async LOAD_CONFIGS (ctx: AppActionContext) {
    ctx.commit('SET_LOADING', true)
    try {
      const data = await rest.apps.getApps()
      ctx.commit('SET_CONFIGS', data)
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },

  async CREATE_NEW (ctx: AppActionContext, data: AppIn) {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      const app = await rest.apps.createApp(data)
      ctx.commit('REPLACE', app)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },

  async RELOAD_CONFIG (ctx: AppActionContext, appId: string) {
    const app = await rest.apps.reloadConfig(appId)
    ctx.commit('REPLACE', app)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    getApp: (state: AppState) => (appID: string): App => state.apps[appID],
    getHelper: (state: AppState) => (appId: string): AppHelper => new AppHelper(state.apps[appId]),
    currentHelper: (state: AppState, _: any, _1: any, rootGetters: any) => {
      const campaign: Campaign|null = rootGetters['campaigns/current']
      if (!campaign) {
        return null
      }
      return new AppHelper(state.apps[campaign.data.app])
    },
    parentHelper: (state: AppState, _: any, _1: any, rootGetters: any): AppHelper | null => {
      const parent: Campaign|null = rootGetters['campaigns/currentParent']
      if (!parent) {
        return null
      }
      return new AppHelper(state.apps[parent.data.app])
    },
    helpers: (state: AppState) => Object.fromEntries(Object.entries(state.apps).map(
      item => [item[0], new AppHelper(item[1])]
    )),
    loading: (state: AppState) => state.loading,
    editLoading: (state: AppState) => state.editLoading,
    apps: (state: AppState) => state.apps,
    currentSlugAround (_: AppState, getters: any): [string, string] {
      const app: AppHelper = getters.currentHelper
      const parentApp: AppHelper|null = getters.parentHelper
      if (!app && !parentApp) {
        return ['', '']
      }
      let currentTmpl = app.rootUrl + app.config.urls.campaign_public
      if (parentApp) {
        // replace the root url with the parent's root url
        currentTmpl = currentTmpl.replace(app.rootUrl, parentApp.rootUrl)
      }
      return currentTmpl.split('{slug}') as [string, string]
    }
  },
  actions
}
