
import { useStore } from '@/composables/use-store'
import { defineComponent, ref, onBeforeMount, computed } from 'vue'
import { PersonalAccessTokenStored } from '@/types/user_settings'
import { copyToClipboard } from '@/util'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'

interface Token {
  id: number
  name: string
  createdAt: string
}

export default defineComponent({
  components: {
    TimeDeltaHuman
  },

  setup () {
    const store = useStore()
    const isValid = ref(false)
    const newTokenName = ref('')
    const newToken = ref<{ token: string } | null>(null) // Temporary state for new token display
    const availablePermissions = computed<string[]>(() => store.getters['permissions/userPermissions'])
    const newTokenPermissions = ref<string[]>([])

    const loading = computed<boolean>(() => store.state.userSettings.loading)
    const tokens = computed<PersonalAccessTokenStored[]>(() => store.state.userSettings.personalAccessTokens)
    const headers = ref([
      // show truncated id
      { text: 'ID', value: 'id' },
      { text: 'Token Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Last Used', value: 'last_used_at' },
      { text: 'Permissions', value: 'permissions' },
      { text: 'Actions', value: 'actions', sortable: false }
    ])

    const rules = {
      required: (value: string) => !!value || 'This field is required'
    }

    const createToken = async () => {
      if (!isValid.value) return
      const response = await store.dispatch(
        'userSettings/CREATE_PERSONAL_ACCESS_TOKEN', { token_name: newTokenName.value, permissions: newTokenPermissions.value }
      )

      // Assuming the response contains the new token's string value in `response.token`
      newToken.value = { token: response.token } // Store the new token in temporary state
      newTokenName.value = ''
    }

    const deleteToken = async (tokenId: string) => {
      if (confirm('Are you sure you want to delete this token?')) {
        await store.dispatch('userSettings/DELETE_PERSONAL_ACCESS_TOKEN', tokenId)
      }
    }

    const copyNewToken = () => {
      if (newToken.value) {
        copyToClipboard(newToken.value.token)
        store.dispatch('snackbar/SHOW_MESSAGE', {
          content: 'Token copied to clipboard',
          timeout: 2000
        })
      }
    }

    const copyId = (id: string) => {
      copyToClipboard(id)
      store.dispatch('snackbar/SHOW_MESSAGE', {
        content: `ID copied to clipboard: ${id}`,
        timeout: 2000
      })
    }

    onBeforeMount(async () => {
      await store.dispatch('userSettings/LOAD_PERSONAL_ACCESS_TOKENS')
    })

    return {
      isValid,
      newTokenName,
      tokens,
      headers,
      rules,
      createToken,
      deleteToken,
      loading,
      newToken,
      copyNewToken,
      copyId,
      availablePermissions,
      newTokenPermissions
    }
  }
})
