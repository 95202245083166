
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { CampaignAccessInfo } from '@/types/permissions'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'
import CampaignPermissionsAdd from '@/components/CampaignPermissionsAdd.vue'

@Component({ components: { TimeDeltaHuman, CampaignPermissionsAdd } })
export default class CampaignPermissions extends Vue {
  @Getter('permissions/currentCampaignAccess') items!: CampaignAccessInfo[]
  @Action('permissions/LOAD_CURRENT_CAMPAIGN_ACCESS') loadAccess!: () => Promise<void>
  @Action('permissions/REMOVE_CURRENT_CAMPAIGN_ACCESS') removeAccess!: (userId: string) => Promise<void>
  @Getter('permissions/loading') loading!: boolean
  @Getter('permissions/canModifyObjectAccess') canModifyObjectAccess!: boolean

  headers = [
    {
      text: 'Granted',
      sortable: true,
      value: 'granted_at',
      sort: (a: Date, b: Date): number => a.getTime() - b.getTime()
    },
    {
      text: 'User name',
      sortable: true,
      value: 'user.display_name',
      sort: (a: string, b: string): number => a.localeCompare(b)
    },
    {
      text: 'User email',
      sortable: true,
      value: 'user.email',
      sort: (a: string, b: string): number => a.localeCompare(b)
    },
    {
      text: 'Role',
      sortable: true,
      value: 'user.role'
    }

  ]

  async created () {
    await this.loadAccess()
    if (this.canModifyObjectAccess) {
      this.headers.push({
        text: 'Actions',
        sortable: false,
        value: 'actions'
      })
    }
  }

  async handleRemove (item: CampaignAccessInfo) {
    await this.removeAccess(item.user.id)
  }
}
