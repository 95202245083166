import { ActionContext } from 'vuex'
import { RootState } from '../types'
import { SnackbarMessage } from '@/types/snackbar'

interface SnackbarState {
  message: SnackbarMessage
  show: boolean
}

type SnackbarActionContext = ActionContext<SnackbarState, RootState>

const state: SnackbarState = {
  message: { content: '' },
  show: false
}

const mutations = {
  SET_MESSAGE (state: SnackbarState, data: SnackbarMessage) {
    state.message = data
    state.show = true
  },

  SET_SHOW (state: SnackbarState, show: boolean) {
    state.show = show
  }

}

const actions = {
  async SHOW_MESSAGE (ctx: SnackbarActionContext, data?: SnackbarMessage) {
    if (data) {
      ctx.commit('SET_MESSAGE', data)
    }
    ctx.commit('SET_SHOW', true)
  },
  HIDE_MESSAGE (ctx: SnackbarActionContext) {
    ctx.commit('SET_SHOW', false)
  }

}

const getters = {
  show: (state: SnackbarState) => state.show,
  message: (state: SnackbarState) => state.message
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
