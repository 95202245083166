import RestClient from './client'
import { User, UserNew } from '@/types/users'
import { App, AppIn } from '@/types/apps'
import { CampaignPatch, CampaignNewIn, CampaignCloneOptions, ExistingId, CampaignEditUsers, CampaignsLoadParams, CampaignData } from '@/types/campaigns'
import { MediaFile, MediaFolder, UploadFileIn, UploadOptimizeParams } from '@/types/uploads'
import { CampaignLockMessage } from '@/types/events'
import { LocaleMap, TranslationConfig, CampaignSheet } from '@/types/i18n'
import { CampaignAccessList } from '@/types/permissions'
import { AppleAlbum, ApplePlaylist } from '@/types/apple'
import { parseSpotifyId, parseAppleId } from '@/parsers'
import { TracklistParams, TracklistInfo, MusicServiceLookupItem, PartialItem } from '@/types/music'
import { TokenGateTestAccount } from '@/types/token_gate'
import { StreamingService } from '@/types/streaming_services'
import { PersonalAccessTokenPrivate, type PersonalAccessTokenStored } from '@/types/user_settings'
import { PartyServiceSearchRecord, PartyServiceSearchResults } from '@/types/partyService'

export class RestModule {
  client: RestClient
  constructor (client: RestClient) {
    this.client = client
  }
}

export class Apps extends RestModule {
  async getApps (): Promise<App[]> {
    const resp = await this.client.get('/api/apps')
    return (await resp.json()).items
  }

  async createApp (data: AppIn): Promise<App> {
    const resp = await this.client.post('/api/apps', { json: data })
    return await resp.json()
  }

  async reloadConfig (appId: string): Promise<App> {
    const resp = await this.client.post(`/api/apps/${appId}/config/reload`)
    return await resp.json()
  }
}

export class Users extends RestModule {
  async getCurrentUser (): Promise<User> {
    const resp = await this.client.get('/api/users/me')
    return (await resp.json())
  }

  async createUser (user: UserNew): Promise<void> {
    await this.client.post('/api/users', {
      json: user,
      includeToken: false
    })
  }

  async updateUserRole (userId: string, role: string): Promise<void> {
    await this.client.post(`/api/users/${userId}/role`, { json: { role } })
  }

  async updateUserState (userId: string, state: string): Promise<void> {
    await this.client.post(`/api/users/${userId}/state`, { json: { state } })
  }

  async updateUserPassword (password: string, token: string): Promise<void> {
    await this.client.put('/api/users/password', {
      json: { new_password: password, token },
      includeToken: false
    })
  }

  /** Request a new password reset email */
  async resetUserPassword (email: string): Promise<void> {
    await this.client.post('/api/users/password_reset', {
      json: { email, cms: true },
      includeToken: false
    })
  }

  async getAllUsers (): Promise<User[]> {
    const resp = await this.client.get('/api/users')
    return (await resp.json())
  }

  /** Send a new verification email to user with given email */
  async sendVerificationEmail (email: string): Promise<void> {
    await this.client.post('/api/users/email_verification/resend', {
      json: { email },
      includeToken: false
    })
  }

  /** Verify the user's email with token */
  async verifyEmail (email: string, token: string): Promise<void> {
    await this.client.post('/api/users/email_verification', {
      json: { email, token },
      includeToken: false
    })
  }
}

export class Campaigns extends RestModule {
  async getAllCampaigns (params: CampaignsLoadParams = {}): Promise<CampaignData[]> {
    const resp = await this.client.get('/api/campaigns', { params })
    return (await resp.json()).items
  }

  async getCampaignChildren (parentId: string): Promise<CampaignData[]> {
    const resp = await this.client.get(`/api/campaigns/${parentId}/children`)
    return (await resp.json()).items
  }

  async getEditUsers (): Promise<CampaignEditUsers> {
    const resp = await this.client.get('/api/campaigns/last_edits')
    return await resp.json()
  }

  async getCampaign (campaignID: string, params: Record<string, string>): Promise<CampaignData> {
    const resp = await this.client.get(`/api/campaigns/${campaignID}`, { params: params })
    return await resp.json()
  }

  async patchCampaign (campaignID: string, data: CampaignPatch, params?: Record<string, string>): Promise<CampaignData> {
    const resp = await this.client.post(`/api/campaigns/${campaignID}/patch`, { json: data, params })
    return await resp.json()
  }

  async createCampaign (data: CampaignNewIn): Promise<CampaignData> {
    const resp = await this.client.post('/api/campaigns', { json: data })
    return await resp.json()
  }

  async cloneCampaign (campaignID: string, options: CampaignCloneOptions = {}): Promise<CampaignData> {
    const resp = await this.client.post(`/api/campaigns/${campaignID}/clone`, {
      json: options
    })
    return await resp.json()
  }

  async startCampaignNow (campaignID: string): Promise<CampaignData> {
    const resp = await this.client.post(`/api/campaigns/${campaignID}/start_now`)
    return await resp.json()
  }

  async endCampaignNow (campaignID: string): Promise<CampaignData> {
    const resp = await this.client.post(`/api/campaigns/${campaignID}/end_now`)
    return await resp.json()
  }

  async slugExists (appId: string, slug: string): Promise<ExistingId> {
    const resp = await this.client.get(`/api/campaigns/${appId}/${slug}/exists`)
    return await resp.json()
  }

  async deleteCampaign (campaignId: string): Promise<void> {
    await this.client.delete(`/api/campaigns/${campaignId}`)
  }

  async lockCampaign (campaignId: string): Promise<void> {
    await this.client.put(`/api/campaigns/${campaignId}/lock`)
  }

  async unlockCampaign (campaignId: string): Promise<void> {
    await this.client.delete(`/api/campaigns/${campaignId}/lock`)
  }

  async getCampaignLocks (): Promise<CampaignLockMessage[]> {
    const resp = await this.client.get('/api/campaigns/locks')
    return await resp.json()
  }
}

export class Uploads extends RestModule {
  async getAllUploads (): Promise<MediaFile[]> {
    const resp = await this.client.get('/api/uploads')
    return (await resp.json()).items
  }

  async createUpload (data: UploadFileIn): Promise<MediaFile> {
    const form = new FormData()
    form.append('file', data.file)
    form.append('folder', data.folder || '/')

    const resp = await this.client.post('/api/uploads', { body: form })
    return await resp.json()
  }

  async optimizeUpload (filename: string, params: UploadOptimizeParams): Promise<MediaFile> {
    const resp = await this.client.post(`/api/uploads/${filename}/optimize`, {
      json: params
    })
    return await resp.json()
  }

  async deleteUpload (filename: string): Promise<void> {
    await this.client.delete(`/api/uploads/${filename}`)
  }

  async deleteFolder (pathname: string): Promise<void> {
    await this.client.delete(`/api/uploads/folders${pathname}`)
  }

  async createFolder (pathname: string): Promise<void> {
    await this.client.post(`/api/uploads/folders${pathname}`)
  }

  async getFolder (pathname: string, byUrl = false): Promise<MediaFolder> {
    const url = byUrl ? '/api/uploads/folders' : `/api/uploads/folders${pathname}`
    const options = byUrl ? { params: { file_url: pathname } } : {}
    const resp = await this.client.get(url, options)
    return await resp.json()
  }
}

export class I18n extends RestModule {
  async getAllLocales (): Promise<LocaleMap> {
    const resp = await this.client.get('/api/i18n/locales', { includeToken: false })
    return await resp.json()
  }

  async getCampaignTranslations (campaignId: string): Promise<TranslationConfig> {
    const resp = await this.client.get(`/api/campaigns/${campaignId}/translations`)
    return await resp.json()
  }

  async setCampaignSheet (
    campaignId: string, data: CampaignSheet
  ): Promise<TranslationConfig> {
    const resp = await this.client.put(`/api/campaigns/${campaignId}/translations/sheet`,
      {
        json: data
      })
    return await resp.json()
  }

  async getCampaignSheet (campaignId: string): Promise<CampaignSheet> {
    const resp = await this.client.get(`/api/campaigns/${campaignId}/translations/sheet`)
    return await resp.json()
  }

  async removeCampaignSheet (campaignId: string): Promise<void> {
    await this.client.delete(`/api/campaigns/${campaignId}/translations/sheet`)
  }
}

export class Permissions extends RestModule {
  async getCampaignAccessList (campaignId: string): Promise<CampaignAccessList> {
    const resp = await this.client.get(`/api/permissions/campaigns/${campaignId}`)
    const data = await resp.json()
    for (const item of data.items) {
      item.granted_at = new Date(item.granted_at)
    }
    return data
  }

  async removeCampaignAccess (campaignId: string, userId: string): Promise<void> {
    await this.client.delete(`/api/permissions/campaigns/${campaignId}/users/${userId}`)
  }

  async grantCampaignAccess (campaignId: string, userId: string): Promise<void> {
    await this.client.post(`/api/permissions/campaigns/${campaignId}`, {
      json: {
        user_id: userId
      }
    })
  }
}

export class Spotify extends RestModule {
  async getArtist (
    query: string,
    params: Record<string, string> = {}
  ): Promise<SpotifyApi.ArtistObjectFull> {
    const artistId = parseSpotifyId(query)
    const resp = await this.client.get(`/api/spotify/artists/${artistId}`, {
      params: params,
      includeToken: false
    })
    return await resp.json()
  }

  async getAlbum (
    query: string,
    params: Record<string, string> = {}
  ): Promise<SpotifyApi.AlbumObjectFull> {
    const albumId = parseSpotifyId(query)
    const resp = await this.client.get(`/api/spotify/albums/${albumId}`, {
      params: params,
      includeToken: false
    })
    return await resp.json()
  }

  async getPlaylist (
    query: string,
    params: Record<string, string> = {}
  ): Promise<SpotifyApi.PlaylistObjectFull> {
    const playlistId = parseSpotifyId(query)
    const resp = await this.client.get(`/api/spotify/playlists/${playlistId}`, {
      params: params,
      includeToken: false
    })
    return await resp.json()
  }

  async getTrack (
    query: string,
    params: Record<string, string> = {}
  ): Promise<SpotifyApi.TrackObjectFull> {
    const trackId = parseSpotifyId(query)
    const resp = await this.client.get(`/api/spotify/tracks/${trackId}`, {
      params: params,
      includeToken: false
    })
    return await resp.json()
  }
}

export class Apple extends RestModule {
  async getAlbum (
    urlOrId: string,
    params: { storefront?: string | null }
  ): Promise<AppleAlbum> {
    const info = parseAppleId(urlOrId, params.storefront)
    const resp = await this.client.get(
      `/api/apple/albums/${info.storefront}/${info.id}`,
      {
        includeToken: false
      }
    )
    return await resp.json()
  }

  async getPlaylist (
    urlOrId: string,
    params: { storefront?: string | null }
  ): Promise<ApplePlaylist> {
    const info = parseAppleId(urlOrId, params.storefront)
    const resp = await this.client.get(
      `/api/apple/playlists/${info.storefront}/${info.id}`,
      {
        includeToken: false
      }
    )
    return await resp.json()
  }
}

export class Music extends RestModule {
  async setTracklist (campaignId: string, params: TracklistParams, dryRun = false): Promise<TracklistInfo> {
    const resp = await this.client.put(`/api/campaigns/${campaignId}/music/tracklist`, {
      json: params,
      params: { dry_run: dryRun.toString() }
    })
    return await resp.json()
  }

  async getTracklistPreview (campaignId: string, params: { search_value?: string|null; item_ids?: string[]|null; partial_items?: PartialItem[] | null }): Promise<TracklistInfo> {
    const qParams: Record<string, any> = {}
    if (params.search_value) {
      qParams.search_value = params.search_value
    }
    if (params.item_ids) {
      qParams.item_ids = params.item_ids
    }
    if (params.partial_items) {
      qParams.partial_items = params.partial_items
    }
    const resp = await this.client.post(`/api/campaigns/${campaignId}/music/tracklist/preview`, {
      json: qParams
    })
    return await resp.json()
  }

  async getTracklist (campaignId: string): Promise<TracklistInfo> {
    const resp = await this.client.get(`/api/campaigns/${campaignId}/music/tracklist`)
    return await resp.json()
  }

  async findItemFromService (identifier: string, enabled_services: StreamingService[] | null = null): Promise<MusicServiceLookupItem> {
    const resp = await this.client.get('/api/music/service_item', {
      params: { identifier, enabled_services: enabled_services }
    })
    return await resp.json()
  }
}

export class TokenGate extends RestModule {
  async getTestAccounts (): Promise<TokenGateTestAccount[]> {
    const resp = await this.client.get('/api/token_gate/test_accounts')
    return (await resp.json()).test_accounts
  }
}

export class UserSettings extends RestModule {
  async getPersonalAccessTokens (): Promise<{items: PersonalAccessTokenStored[]}> {
    const resp = await this.client.get('/api/users/me/personal_access_tokens')
    return await resp.json()
  }

  async createPersonalAccessToken (data: { token_name: string; permissions: string[] }): Promise<PersonalAccessTokenPrivate> {
    const resp = await this.client.post('/api/users/me/personal_access_tokens', { json: data })
    return await resp.json()
  }

  async deletePersonalAccessToken (id: string): Promise<void> {
    await this.client.delete(`/api/users/me/personal_access_tokens/${id}`)
  }
}

export class PartyService extends RestModule {
  async search (params: { query: string; limit: number; offset: number }): Promise<PartyServiceSearchResults> {
    const resp = await this.client.get('/api/party_service/search', { params })
    return await resp.json()
  }
}
