
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import SlugNameEdit from '@/components/SlugNameEdit.vue'
import { AppIn } from '@/types/apps'

@Component({ components: { SlugNameEdit } })
export default class AppNew extends Vue {
  @Action('apps/CREATE_NEW') createApp!: (data: AppIn) => Promise<void>
  @Getter('apps/editLoading') loading!: boolean

  formData = { id: '', name: '', config_url: '' }

  set nameSlug (value) {
    this.formData.name = value.name
    this.formData.id = value.slug
  }

  get nameSlug () {
    return { slug: this.formData.id, name: this.formData.name }
  }

  async submit () {
    await this.createApp(this.formData)
    this.formData = { id: '', name: '', config_url: '' }
  }
}
