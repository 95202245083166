
import SideNavLink from '@/components/SideNavLink.vue'
import { defineComponent, computed } from 'vue'
import UserSettingsPersonalAccessTokensVue from '@/components/UserSettingsPersonalAccessTokens.vue'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  components: {
    SideNavLink,
    UserSettingsPersonalAccessTokensVue
  },

  setup () {
    const route = useRoute()
    const currentView = computed<string>(() => route.name || '')

    return {
      currentView
    }
  }

})
