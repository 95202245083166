
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'
import { CampaignClonePayload, CampaignData, CampaignLock } from '@/types/campaigns'
import AppHelper from '@/AppHelper'
import { Action, Getter } from 'vuex-class'
import { makeReportUrl } from '@/lib/reports'
import { Location } from 'vue-router'
import { Campaign } from '@/Campaign'

@Component({ components: { TimeDeltaHuman } })
export default class CampaignsTable extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Array, required: true }) campaigns!: Campaign[]
  @Prop({ type: Boolean, default: false }) pickerMode!: boolean

  @Action('campaigns/CLONE') clone: (payload: CampaignClonePayload) => Promise<CampaignData>
  @Action('campaigns/DELETE') deleteCampaign: (c: CampaignData) => Promise<void>
  @Getter('apps/helpers') apps!: { [key: string]: AppHelper }
  @Getter('campaigns/locks') locks: Record<string, CampaignLock>
  @Getter('permissions/canDeleteCampaigns') canDeleteCampaigns!: boolean
  @Getter('permissions/canCreateCampaigns') canCreateCampaigns!: boolean

  headers =[
    {
      text: 'App',
      sortable: true,
      value: 'data.app'
    },
    {
      text: 'Name',
      sortable: true,
      value: 'data.name'
    },
    {
      text: 'Created',
      sortable: true,
      value: 'data.created_at'
      // sort: (a: Date, b: Date): number => a.getTime() - b.getTime()
    },
    {
      text: 'Updated',
      sortable: true,
      value: 'data.updated_at'
      // sort: (a: Date, b: Date): number => a.getTime() - b.getTime()
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }]

  getLink (slug: string, appId: string) {
    const app = this.apps[appId]
    return app.campaignUrl(slug)
  }

  getLockUsername (campaignId: string): string|null {
    const lock = this.locks[campaignId]
    if (!lock) { return null }
    return lock.user.display_name
  }

  reportHref (campaign: Campaign): string | null {
    if (this.apps[campaign.data.app].hasReports) {
      return null
    }
    return makeReportUrl(campaign)
  }

  reportLinkTo (campaign: Campaign): Location | null {
    if (!this.apps[campaign.data.app].hasReports) {
      return null
    }
    return { name: 'campaign-reports', params: { id: campaign.data.id } }
  }

  prePageLink (campaign: Campaign): Location | null {
    if (!campaign.data.token_gate_id) {
      return null
    }
    return { name: 'campaign-settings', params: { id: campaign.data.token_gate_id, tab: 'pre-page' } }
  }

  async handleClone (campaign: Campaign) {
    const result = await this.clone({ id: campaign.data.id })
    this.$router.push({ name: 'campaign-settings', params: { id: result.id } })
  }

  async handleDelete (campaign: Campaign): Promise<void> {
    let msg = 'Campaign will be PERMANENTLY deleted.\n'
    msg += `To confirm, enter the name of the campaign "${campaign.data.name}"`
    const p = prompt(msg)
    if (p === campaign.data.name) {
      await this.deleteCampaign(campaign.data)
    }
  }

  onRowClicked (row: Campaign, _: any, event: Event) {
    if (event.target && (event.target as HTMLElement).closest('*[data-row-click-ignore="true"]')) {
      // No pick mode for action buttons
      return
    }
    this.$emit('pick', row)
  }

  itemClass (_: Campaign) {
    return { 'campaigns-table-row-pickable': this.pickerMode }
  }
}
