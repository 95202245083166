import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import apps from './modules/apps'
import users from './modules/users'
import campaigns from './modules/campaigns'
import uploads from './modules/uploads'
import snackbar from './modules/snackbar'
import i18n from './modules/i18n'
import permissions from './modules/permissions'
import music from './modules/music'
import userSettings from './modules/userSettings'
import partyService from './modules/partyService'
import global from './modules/global'

Vue.use(Vuex)

const createStore = () => new Vuex.Store({
  state: {},
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    apps,
    users,
    campaigns,
    uploads,
    snackbar,
    i18n,
    permissions,
    music,
    userSettings,
    partyService,
    global
  },
  getters: {}
})

export default createStore
