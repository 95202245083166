
import { defineComponent, ref } from 'vue'
import PartyServiceSearch from './PartyServiceSearch.vue'

export default defineComponent({
  components: {
    PartyServiceSearch
  },

  props: {
    value: {
      type: String,
      required: false
    }
  },

  emits: ['input'],

  setup (props, { emit }) {
    const dialog = ref(false)
    const onInput = (value: number) => {
      emit('input', value)
      dialog.value = false
    }

    return {
      dialog,
      onInput
    }
  }
})
