import format from 'string-format'

import { App, AppConfig } from '@/types/apps'
import { StreamingService } from './types/streaming_services'

export default class AppHelper {
  app: App

  constructor (app: App) {
    this.app = app
  }

  get id (): string {
    return this.app.id
  }

  get name (): string {
    return this.app.name
  }

  get rootUrl (): string {
    return this.app.root_url
  }

  get lastUpdateSuccess (): boolean {
    return this.app.last_update_success
  }

  get updatedAt (): Date {
    return new Date(this.app.updated_at)
  }

  get createdAt (): Date {
    return new Date(this.app.created_at)
  }

  get config (): AppConfig {
    return this.app.config
  }

  get configJson (): string {
    return JSON.stringify(this.config, null, 2)
  }

  get configUrlAbsolute (): string {
    return this.rootUrl + this.app.config_url
  }

  get campaignPrefixSuffix (): [string, string] {
    const path = this.app.config.urls.campaign_public
    const [prefix, suffix] = path.split('{slug}')
    return [this.rootUrl + prefix, suffix]
  }

  editorUrl (appCampaignId: string): string {
    return this.app.root_url + format(
      this.app.config.urls.editor, {
        id: appCampaignId
      }
    )
  }

  campaignUrl (slug: string): string {
    const [prefix, suffix] = this.campaignPrefixSuffix
    return prefix + slug + suffix
  }

  absoluteUrl (url: string): string {
    return this.rootUrl + url
  }

  get hasReports (): boolean {
    return this.app.config.reports.length > 0
  }

  get translationsEnabled (): boolean {
    return this.app.config.translations_enabled
  }

  get musicEnabled (): boolean {
    return this.app.config.music_enabled
  }

  get newsletterEnabled (): boolean {
    return this.app.config.newsletter_enabled
  }

  get followEnabled (): boolean {
    return Object.values(this.app.config.streaming_services).some(item => item !== null)
  }

  get tokenGateEnabled (): boolean {
    return this.app.config.token_gate_enabled
  }

  get startOrEndDateEnabled (): boolean {
    return this.app.config.start_date_enabled || this.app.config.end_date_enabled
  }

  get startDateEnabled (): boolean {
    return this.app.config.start_date_enabled
  }

  get endDateEnabled (): boolean {
    return this.app.config.end_date_enabled
  }

  get startDateRequired (): boolean {
    return this.app.config.start_date_required
  }

  get endDateRequired (): boolean {
    return this.app.config.end_date_required
  }

  get topLevel (): boolean {
    return this.app.config.top_level
  }

  defaultTransalationMessages (localeCode: string): Record<string, string|string[]> {
    const translations = this.app.config.default_translations
    const defaultLocale = this.app.config.default_locale

    if (translations.length === 0) {
      return {}
    }

    const matchLocale = (
      translations.find(t => t.locale.code === localeCode)
    ) || translations.find(t => t.locale.code === localeCode.split('-')[0]) || translations.find(
      t => t.locale.code === defaultLocale
    )

    return matchLocale?.messages || {}
  }

  /**
   * Names of enabled streaming services
   */
  get streamingServiceNames (): StreamingService[] {
    return Object.keys(this.app.config.streaming_services).filter(
      // @ts-ignore
      key => this.app.config.streaming_services[key] !== null
    ) as StreamingService[]
  }
}
