import { Campaign } from '@/Campaign'

export function makeReportUrl (campaign: Campaign): string|null {
  const url = new URL(window.location.origin)
  url.pathname = '/reports'
  if (campaign.data.app === 'listening-party') {
    url.hash = `/lsp/parties/${campaign.data.app_id}`
  } else if (campaign.data.app === 'community-playlist' || campaign.data.app === 'community-playlist-2') {
    url.hash = `/cmpl/campaigns/${campaign.data.app_id}`
  } else if (campaign.data.app === 'listen-and-unlock') {
    url.hash = `/heatmap/competitions/${campaign.data.app_id}`
  } else {
    return null
  }
  return url.toString()
}
