
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class MediaItemCard extends Vue {
  @Prop({ type: String, default: '' }) subtitle!: string
  @Prop() title!: string
  @Prop() thumbnail!: string
  @Prop({ type: Boolean, default: false }) thumbnailIcon!: boolean
  @Prop({ type: String, default: null }) to!: string | null
  @Prop({ type: String, default: null }) createdByName!: string | null

  @Getter('permissions/canDeleteMedia') canDeleteMedia!: boolean

  get parentComponent () {
    return this.to ? 'router-link' : 'span'
  }
}
