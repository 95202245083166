
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReportDefinition, ReportDownload } from './types'
import SpinnerFull from '@/components/SpinnerFull.vue'
import DataTable from './components/DataTable.vue'
import StatsTiles from './components/StatsTiles.vue'
import RaffleWinnersTable from './components/RaffleWinnersTable.vue'
import BarChart from './components/BarChart.vue'
import ZoomableTimeseriesChart from './components/ZoomableTimeSeriesChart.vue'
import DataTableGroup from './components/DataTableGroup.vue'
import DownloadButton from './components/DownloadButton.vue'

@Component({
  components: {
    SpinnerFull,
    DataTable,
    StatsTiles,
    RaffleWinnersTable,
    BarChart,
    ZoomableTimeseriesChart,
    DataTableGroup,
    DownloadButton
  }
})
export default class ReportItem extends Vue {
  @Prop() data!: ReportDefinition | null
  @Prop() loading!: boolean

  get dataLoaded (): boolean {
    return !this.loading && !!this.data
  }

  get componentName (): string|null {
    const available = [
      'data-table',
      'stats-tiles',
      'raffle-winners-table',
      'bar-chart',
      'zoomable-timeseries-chart',
      'data-table-group'
    ]

    if (this.data && available.includes(this.data.component)) {
      return this.data.component
    }
    return null
  }

  get hasDownloads (): boolean {
    return !!this.data?.downloads && this.data.downloads.length > 0
  }

  get downloads (): ReportDownload[] {
    return this.data?.downloads || []
  }
}
