
import { useStore } from '@/composables/use-store'
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'
import { debounce } from '@/util'
import { PartyServiceSearchRecord, PartyServiceSearchResults } from '@/types/partyService'
import PartyItem from './PartyItem.vue'

export default defineComponent({
  components: {
    PartyItem
  },

  props: {
    value: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const store = useStore()
    const model = ref(null)
    const query = ref('')
    const items = ref<PartyServiceSearchRecord[]>([])
    const totalResults = ref<number>(0)
    const limit = ref<number>(20)
    const loading = computed<boolean>(() => store.getters['partyService/loading'])

    const totalPages = computed<number>(() => Math.ceil(totalResults.value / limit.value))
    // const totalPages = 40
    const currentPage = ref(1)

    const offset = computed<number>(() => (currentPage.value - 1) * limit.value)

    const _fetchItems = async (): Promise<void> => {
      if (!query.value) {
        items.value = []
        totalResults.value = 0
        return
      }
      const queryAtStart = query.value
      const pageAtStart = currentPage.value
      const results: PartyServiceSearchResults = await store.dispatch('partyService/SEARCH', { query: query.value, offset: offset.value, limit: limit.value })
      // Throw result away if query has changed while loading
      if (queryAtStart === query.value && pageAtStart === currentPage.value) {
        items.value = results.records
        totalResults.value = results.total
        limit.value = results.limit
      }
    }

    const fetchItems = debounce(_fetchItems, 300)

    const onPageChanged = async (page: number) => {
      console.log('Current page', page)
      await fetchItems()
    }

    const resetPageAndFetch = async () => {
      currentPage.value = 1
      await fetchItems()
    }

    watch(query, resetPageAndFetch)

    if (props.value) {
      query.value = props.value || ''
    }

    return {
      model,
      query,
      items,
      loading,
      totalResults,
      totalPages,
      currentPage,
      onPageChanged
    }
  }
})
