
export class FolderNotFound extends Error {
  constructor (pathname: string) {
    super(`Folder '${pathname}' not found`)
    this.name = 'FolderNotFound'
  }
}

export class FileNotFound extends Error {
  constructor (pathname: string) {
    super(`File '${pathname}' not found`)
    this.name = 'FileNotFound'
  }
}
