
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { CampaignPatch } from '@/types/campaigns'
import { Translation, SearchLocale } from '@/types/i18n'
import LocaleSearch from './LocaleSearch.vue'
import LocaleListItem from './LocaleListItem.vue'
import NewLocale from './NewLocale.vue'
import MessagePanel from './MessagePanel.vue'
import LocaleSettings from './LocaleSettings.vue'
import SaveChangesBar from '@/components/SaveChangesBar.vue'
import Sheet from './Sheet.vue'
import AppHelper from '@/AppHelper'
import { Campaign } from '@/Campaign'

@Component({
  components: {
    LocaleSearch,
    LocaleListItem,
    NewLocale,
    MessagePanel,
    LocaleSettings,
    SaveChangesBar,
    Sheet
  }
})
export default class CampaignTranslations extends Vue {
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('campaigns/currentLocales') localesMap!: Record<string, Translation>
  @Getter('campaigns/currentHasTranslationSheet') hasSheet!: boolean
  @Action('campaigns/EDIT_CURRENT') editCurrent!: (data: CampaignPatch) => Promise<void>
  @Getter('apps/currentHelper') app!: AppHelper

  panel = []
  editLocale: string | null = null
  messageUpdates = {}
  unsavedChanges = false

  created () {
    this.editLocale = Object.values(this.localesMap)[0]?.locale.code || null
  }

  get localesList () {
    const ret = Object.values(this.localesMap).map(
      (l) => (
        {
          text: l.locale.name,
          value: l.locale.code
        })
    )
    return ret
  }

  get currentTranslation (): Translation | null {
    return this.editLocale ? this.localesMap[this.editLocale] || null : null
  }

  get messages (): Record<string, string|string[]> {
    if (!this.editLocale) {
      return {}
    }
    return {
      ...(this.currentTranslation?.messages || {})
    }
  }

  messageChanged (messageId: string, value: string|string[]) {
    this.$store.commit('campaigns/UPDATE_TRANSLATION_MESSAGE', {
      locale: this.editLocale,
      messageId: messageId,
      value: value
    })
    this.unsavedChanges = true
  }

  deleteTranslation (): void {
    if (!window.confirm('Are you sure?')) {
      return
    }
    this.$store.commit('campaigns/DELETE_TRANSLATION', this.editLocale)
    this.editLocale = Object.keys(this.localesMap)[0]
    this.unsavedChanges = true
  }

  get currentLocale () {
    return {
      name: this.currentTranslation?.locale.name || '',
      isDefault: this.campaign.data.i18n?.default_locale === this.editLocale
    }
  }

  set currentLocale (value) {
    this.$store.commit('campaigns/UPDATE_LOCALE', {
      locale: this.editLocale,
      ...value
    })
    this.unsavedChanges = true
  }

  createTranslation (locale: SearchLocale): void {
    if (locale.code in this.localesMap) {
      return
    }
    const newMessages = this.app.defaultTransalationMessages(locale.code)
    const newTranslation = {
      locale: {
        name: locale.name,
        code: locale.code,
        plural_form: locale.plural_form
      },
      // TODO: Messages should use default when no locale exists
      messages: newMessages || {}
    }
    this.$store.commit('campaigns/ADD_TRANSLATION', newTranslation)
    this.editLocale = newTranslation.locale.code
    this.unsavedChanges = true
  }

  get nPlurals (): number {
    return this.currentTranslation?.locale.plural_form?.nplurals || 2
  }

  async saveChanges () {
    await this.editCurrent({ i18n: this.campaign.data.i18n })
    this.unsavedChanges = false
  }
}
