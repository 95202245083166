
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { CampaignPatch, CampaignSettingsFormData, ExistingId } from '@/types/campaigns'
import MediaPickerDialog from '@/components/MediaPickerDialog.vue'
import SocialPreview from '@/components/SocialPreview.vue'
import SlugNameEdit from '@/components/SlugNameEdit.vue'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'
import StreamingFollow from './StreamingFollow.vue'
import Newsletter from './Newsletter.vue'
import AppHelper from '@/AppHelper'
import DateTimePicker from '@/components/util/DateTimePicker.vue'
import PartyServiceSearchDialog from './PartyServiceSearchDialog.vue'
import { Campaign } from '@/Campaign'

interface NameSlug {
  name: string
  slug: string
}

interface ExistsParams {
  appId: string
  slug: string
}

@Component({
  components: {
    MediaPickerDialog,
    SlugNameEdit,
    TimeDeltaHuman,
    Newsletter,
    StreamingFollow,
    SocialPreview,
    DateTimePicker,
    PartyServiceSearchDialog
  }
})
export default class CampaignSettings extends Vue {
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('campaigns/editLoading') submitLoading!: boolean
  @Getter('apps/currentHelper') app!: AppHelper
  @Getter('apps/parentHelper') parentApp!: AppHelper|null
  @Getter('apps/currentSlugAround') slugAround!: [string, string]
  @Action('campaigns/EDIT_CURRENT') editCampaign!: (data: CampaignPatch) => Promise<void>
  @Action('campaigns/CHECK_SLUG_EXISTS') slugExists!: (
    data: ExistsParams
  ) => Promise<ExistingId>

  @Action('campaigns/END_CURRENT') endCampaign!: () => Promise<void>
  @Action('campaigns/START_CURRENT') startCampaign!: () => Promise<void>

  valid=true

  // @ts-ignore
  formData: CampaignSettingsFormData = {} // This must be set to an object initially, otherwise the watcher won't fire
  unsavedChanges = false
  watcherOn = true
  slugDuplicateId: string | null = null

  @Watch('formData', { deep: true, immediate: true })
  onPropertyChanged (newValue: CampaignPatch, oldValue: CampaignPatch) {
    if (!this.watcherOn || this.unsavedChanges || !oldValue || !Object.keys(oldValue).length) {
      return
    }
    this.unsavedChanges = true
  }

  created () {
    this.reloadData()
  }

  get updatedAt () {
    return new Date(this.campaign.data.updated_at)
  }

  reloadData () {
    this.watcherOn = false
    const c = this.campaign
    this.formData = {
      name: c.data.name,
      slug: c.data.slug,
      tag_manager: { ...c.data.tag_manager },
      meta_tags: { ...c.data.meta_tags },
      test: c.data.test,
      newsletter: { ...JSON.parse(JSON.stringify(c.data.newsletter)) },
      follow: JSON.parse(JSON.stringify(c.data.follow)),
      starts_at: c.data.starts_at,
      ends_at: c.data.ends_at
    }
    this.$nextTick(() => { this.watcherOn = true })
  }

  get nameAndSlug (): NameSlug {
    return { slug: this.formData.slug || '', name: this.formData.name || '' }
  }

  set nameAndSlug (value: NameSlug) {
    this.formData.name = value.name
    this.formData.slug = value.slug
    this.validateSlug(value.slug)
  }

  onSlugEditToggled (state: boolean) {
    if (state) {
      this.slugDuplicateId = null
    }
  }

  async validateSlug (slug: string) {
    if (!slug) {
      return
    }
    const result = await this.slugExists({ appId: this.campaign.data.app, slug })
    if (!result.exists || result.existing_id === this.campaign.data.id) {
      return
    }
    this.slugDuplicateId = result.existing_id
  }

  async startCampaignNow () {
    if (!confirm('The campaign will start immediately. Are you sure?')) {
      return
    }
    await this.startCampaign()
    this.reloadData()
  }

  async endCampaignNow () {
    if (!confirm('The campaign will end immediately. Are you sure?')) {
      return
    }
    await this.endCampaign()
    this.reloadData()
  }

  async submit () {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }
    await this.editCampaign(this.formData)
    this.reloadData()
    this.unsavedChanges = false
  }
}
