import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Admin from '@/views/Admin.vue'
import Home from '@/views/Home.vue'
import Media from '@/views/Media.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import PasswordResetNew from '@/views/PasswordResetNew.vue'
import EmailVerificationRequest from '@/views/EmailVerificationRequest.vue'
import Campaign from '@/views/Campaign.vue'
import CampaignNew from '@/views/CampaignNew.vue'
import Users from '@/views/Users.vue'
import UserNew from '@/views/UserNew.vue'
import Apps from '@/views/Apps.vue'
import UserSettings from '@/views/UserSettings.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Admin,
    children: [
      {
        path: '',
        redirect: '/campaign'
      },
      {
        path: '/campaign',
        name: 'campaigns',
        component: Home
      },
      {
        path: 'media/*',
        name: 'media-folder',
        component: Media,
        props: route => {
          const props = { folderPath: route.params.pathMatch }
          return props
        }
      },
      {
        path: '/media',
        name: 'media',
        component: Media
      },
      {
        path: '/apps',
        name: 'apps',
        component: Apps
      },
      {
        path: '/user',
        name: 'users',
        component: Users
      },
      {
        path: '/user/settings',
        name: 'user-settings',
        component: UserSettings,
        children: [
          {
            name: 'user-settings-personal-access-tokens',
            path: 'personal-access-tokens'
          }
        ]
      },
      {
        path: '/user/new',
        name: 'userNew',
        component: UserNew
      },
      {
        path: '/campaign/new',
        name: 'campaignNew',
        component: CampaignNew
      },
      {
        path: '/campaign/:id',
        name: 'campaign',
        props: true,
        component: Campaign,
        children: [
          {
            name: 'campaign-settings',
            path: 'settings',
            props: true,
            children: [
              {
                name: 'campaign-settings-media',
                path: 'media(/.+)?'
              }
              // {
              //   name: 'campaign-settings-root-media',
              //   path: 'media'
              // }
            ]
          },
          {
            name: 'campaign-music',
            path: 'music',
            props: true
          },
          {
            name: 'campaignEditor',
            path: 'editor',
            props: true
          },
          {
            name: 'campaignTranslations',
            path: 'translations',
            props: true
          },
          {
            name: 'campaign-reports',
            path: 'reports',
            props: true
          },
          {
            name: 'campaign-permissions',
            path: 'permissions',
            props: true
          },
          {
            name: 'campaign-token-gate',
            path: 'token-gate',
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset-new',
    component: PasswordResetNew
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: EmailVerificationRequest,
    props: route => ({ email: route.query.email, token: route.query.token, newUser: route.query.newUser })
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  if (!to.matched.find(item => item.name === 'campaign')) {
    // Reset title for none campaign routes
    document.title = 'CMS'
  }
  next()
})

export default router
