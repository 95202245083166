
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

interface ValueProp {
  name: string
  isDefault: boolean
}

@Component
export default class LocaleSettings extends Vue {
  @Prop({ type: Object }) value!: ValueProp
  @Getter('campaigns/currentHasTranslationSheet') hasSheet!: boolean

  get isDefault (): boolean {
    return this.value.isDefault
  }

  set isDefault (value: boolean) {
    this.$emit('input', { ...this.value, isDefault: value })
  }

  get name (): string {
    return this.value.name
  }

  set name (value: string) {
    this.$emit('input', { ...this.value, name: value })
  }
}
