
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import rest from '@/rest'
import { CampaignFollowItem } from '@/types/campaigns'

@Component
export default class FollowItem extends Vue {
  @Prop() item: CampaignFollowItem
  name = ''
  image: string|null = null

  created () {
    this.searchCurrent()
  }

  get subtitle () {
    return `${this.item.service} ${this.item.type}`
  }

  @Watch('item')
  async onItemChange () {
    await this.searchCurrent()
  }

  async searchCurrent () {
    await this.searchItem(this.item.id)
  }

  async searchItem (identifier: string) {
    if (!identifier) {
      return
    }
    if (!this.item.label) {
      const item = await rest.music.findItemFromService(identifier)
      this.name = item.label
      this.image = item.image
    } else {
      this.name = this.item.label
      this.image = this.item.image
    }
  }
}
