
import { defineComponent, onBeforeMount, Ref, ref } from 'vue'
import { generateOAuthParams, handleCallback } from '@/auth/oauth'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const authorizationUrl: Ref<string> = ref('')
    let nextUrl = (route.query.next || '/').toString()
    const resolved = router.resolve(nextUrl)
    if (resolved.location.path === route.path) {
      nextUrl = '/'
    }

    onBeforeMount(async () => {
      let loggedIn = false
      try {
        loggedIn = await handleCallback()
      } finally {
        // Make sure we always generate authorization URL
        // so that login button still works in case the callback params are invalid
        const oauthParams = await generateOAuthParams()
        authorizationUrl.value = oauthParams.authorizationUrl
      }
      if (loggedIn) {
        router.replace(nextUrl)
      }
    })

    return {
      authorizationUrl
    }
  }
})
