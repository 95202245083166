
class Env {
  get environment (): 'local' | 'staging' | 'production' {
    if (window.location.hostname === 'backend.universalmusic.digital') {
      return 'production'
    } else if (window.location.hostname === 'dev.backend.universalmusic.digital') {
      return 'staging'
    } else {
      return 'local'
    }
  }

  get isLive (): boolean {
    return this.environment === 'production'
  }
}

const env = new Env()

export default env
