
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import AppHelper from '@/AppHelper'
import rest from '@/rest'
import { ReportDefinition } from './types'
import ReportItem from './ReportItem.vue'
import { Campaign } from '@/Campaign'

interface DataState {
  loading: boolean
  data: ReportDefinition | null
}

@Component({ components: { ReportItem } })
export default class ReportWrapper extends Vue {
  @Getter('campaigns/current') campaign!: Campaign
  @Getter('apps/currentHelper') app!: AppHelper
  data: (DataState[])[] = []

  created () {
    const sections = this.app.config.reports

    for (const [rowIdx, section] of sections.entries()) {
      this.data.push([])
      for (const _ of section.entries()) {
        this.data[rowIdx].push({ data: null, loading: true })
      }
    }
    this.loadData()
  }

  makeUrl (url: string): string {
    return this.app.rootUrl + url.replace('{id}', this.campaign.data.app_id || '0')
  }

  get reportSections () {
    return this.app.config.reports
  }

  async loadItem (url: string) {
    const finalUrl = this.makeUrl(url)
    const resp = await rest.get(finalUrl)
    return await resp.json()
  }

  async loadData () {
    const sections = this.app.config.reports

    for (const [rowIdx, section] of sections.entries()) {
      for (const [colIdx, item] of section.entries()) {
        const data = await this.loadItem(item.data_url)
        this.data[rowIdx][colIdx].loading = false
        this.data[rowIdx][colIdx].data = data
      }
    }
  }
}
