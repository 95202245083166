
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { User } from '@/types/users'
import { CampaignAccessInfo } from '@/types/permissions'
import { UNLIMITED_ROLES } from '@/roles'

@Component
export default class ComponentName extends Vue {
  @Action('users/LOAD_LIST') loadData: () => Promise<void>
  @Action('permissions/GRANT_CURRENT_CAMPAIGN_ACCESS') grantAccess: () => Promise<void>
  @Getter('permissions/loading') permissionsLoading!: boolean
  @Getter('users/list') data: User[]
  @Getter('users/listLoading') listLoading: boolean
  @Getter('permissions/currentCampaignAccess') accessItems!: CampaignAccessInfo[]

  headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'display_name'
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email'
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'actions'

    }
  ]

  async created () {
    await this.loadData()
  }

  get loading (): boolean {
    return this.permissionsLoading || this.listLoading
  }

  get usersFiltered (): User[] {
    const currentIds = this.accessItems.filter(
      (item: CampaignAccessInfo) => !UNLIMITED_ROLES.includes(item.user.role)
    ).map((item: CampaignAccessInfo) => item.user.id)

    return this.data.filter(
      (user: User) => (
        user.state === 'approved' &&
          !UNLIMITED_ROLES.includes(user.role) &&
          !currentIds.includes(user.id)
      )
    )
  }
}
