
import { Component, Vue, Prop } from 'vue-property-decorator'
import { StatsTilesDefinition } from '../types'

@Component
export default class StatsTiles extends Vue {
  @Prop() data!: StatsTilesDefinition

  formattedValue (value: string|number): string {
    if (typeof value === 'number') {
      return value.toLocaleString()
    }
    return value
  }
}
