
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SocialPreviewTwitter extends Vue {
  @Prop() image!: string | null
  @Prop() title!: string | null
  @Prop() description!: string | null
  @Prop() aspectRatio!: string

  truncatedString (s: string|null, length: number): string {
    if (!s) {
      return ''
    }
    const suffix = s.length > length ? '...' : ''
    return s.substr(0, length - 1) + suffix
  }

  get titleTruncated (): string {
    return this.truncatedString(this.title, 65)
  }

  get descriptionTruncated (): string {
    return this.truncatedString(this.description, 200)
  }
}
