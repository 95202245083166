import { RestModule } from './modules'
import { sleep } from '@/util'

export interface ErrorPayload {
  statusCode: number
  retry: boolean
  retryAfter: number | null
  attempt: number
}

type MessageHandler = (msg: any) => any
type ConnectHandler = () => any
type ErrorHandler = (payload: ErrorPayload) => any

export class Socket extends RestModule {
  socket: WebSocket
  heartbeatInterval = 20000
  errorCount = 0
  onMessageInner: MessageHandler | null = null
  firstMessage = true
  lastConnectedAt: Date | null = null
  public onConnect: ConnectHandler | null = null
  public onError: ErrorHandler | null = null

  public set onMessage (f: MessageHandler|null) {
    this.onMessageInner = f
  }

  public get onMessage (): MessageHandler|null {
    return this.onMessageInner || null
  }

  public connect () {
    this.createSocket()
  }

  private newSocket () {
    this.socket = this.createSocket()
  }

  private async errorCountReset (socket: WebSocket) {
    // Reset error count to 0 if socket is still connected
    // after 10 seconds
    const lastConnect = this.lastConnectedAt
    await sleep(10000)
    if (socket.readyState === socket.OPEN && lastConnect === this.lastConnectedAt) {
      this.errorCount = 0
    }
  }

  private get url (): string {
    const root = new URL(this.client.host)
    root.protocol = root.protocol === 'http:' ? 'ws' : 'wss'
    root.pathname = '/api/events'
    return root.toString()
  }

  private createSocket () {
    const socket = new WebSocket(this.url)

    socket.onmessage = (event) => {
      this.firstMessage = false
      if (this.onConnect) {
        this.onConnect()
      }
      if (!event.data) {
        return
      }
      if (event.data === 'ping') {
        return
      }
      const msg = JSON.parse(event.data)
      console.log(event.data)
      if (!this.onMessageInner) {
        return
      }
      this.onMessageInner(msg)
    }

    socket.onclose = (e) => {
      console.log('Socket closed', e)
      this.errorCount += 1
      // Code 1008 is authentication error, don't retry
      if (this.errorCount > 7 || e.code === 1008) {
        if (this.onError) {
          this.onError({
            statusCode: e.code,
            retry: false,
            retryAfter: null,
            attempt: this.errorCount
          })
        }
        return
      }
      const retryTime = this.jitter((0.3 * (2 ** (this.errorCount) - 1)) * 1000)
      console.log('Retry socket in', retryTime, 'ms, attempt', this.errorCount)

      if (this.onError) {
        this.onError({
          statusCode: e.code,
          retry: true,
          retryAfter: retryTime,
          attempt: this.errorCount
        })
      }

      setTimeout(() => this.newSocket(), retryTime)
    }

    socket.onopen = async (e) => {
      this.lastConnectedAt = new Date()
      this.firstMessage = true
      const target = (e.target as WebSocket)
      this.errorCountReset(target)
      const token = await this.client.getAccessToken()
      target.send(JSON.stringify({ type: 'authorize', access_token: token }))
      this.heartbeat((e.target as WebSocket))
    }

    // socket.onopen = (e) => this.heartbeat((e.target as WebSocket))
    return socket
  }

  private jitter (value: number): number {
    return value + Math.ceil(Math.random() * 1000)
  }

  private heartbeat (target: WebSocket | null) {
    if (!target) {
      return
    }
    if (target.readyState !== target.OPEN) {
      return
    }
    target.send('ping')
    setTimeout(() => this.heartbeat(target), this.jitter(this.heartbeatInterval))
  }
}
