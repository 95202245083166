
import { Component, Prop, Vue } from 'vue-property-decorator'
import { formatMsToMinutes } from '@/util'
import { MusicTrack } from '@/types/music'

@Component
export default class TracklistTable extends Vue {
  formatMsToMinutes = formatMsToMinutes

  @Prop({ type: Array, required: true }) tracks!: MusicTrack[]
  @Prop({ type: Boolean, required: true }) loading!: boolean

  tableHeaders = [
    { text: 'Image', value: 'image' },
    { text: 'Name', value: 'name' },
    { text: 'Artists', value: 'artists' },
    { text: 'Duration', value: 'duration_ms' },
    { text: 'Services', value: 'service_id' }
  ]
}
