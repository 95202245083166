
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReportDownload } from '../types'
import { Getter } from 'vuex-class'
import rest from '@/rest'
import AppHelper from '@/AppHelper'
import { Campaign } from '@/Campaign'

@Component
export default class DownloadButton extends Vue {
  @Prop() data!: ReportDownload

  @Getter('apps/currentHelper') app!: AppHelper
  @Getter('campaigns/current') campaign!: Campaign

  async downloadReport (): Promise<void> {
    const absUrl = this.app.absoluteUrl(this.data.url)
    const resp = await rest.get(absUrl)
    const a = document.createElement('a')
    a.href = URL.createObjectURL(await resp.blob())
    a.download = this.campaign.data.slug + '-' + (this.data?.title || '') + '.' + this.data.format.toLowerCase()
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
}
